/* eslint-disable array-callback-return */
import {
  GET_GEOLOCATION_LEVEL1_OPTIONS_SUCCESS,
  GET_GEOLOCATION_LEVEL1_OPTIONS_ERROR,
  SET_GEOLOCATION_LEVEL1_OPTIONS,
  LOADING_REQUEST,
  GET_GEOLOCATION_FIELDS_SUCCESS,
  GET_GEOLOCATION_FIELDS_ERROR,
  GET_ALL_PARTNER_TYPES,
  GET_SOH_DETAILS_SUCCESS,
  SET_ALL_SOHLEVEL_SELECTED_VALUES,
  SET_SELECTED_MAPPED_SOH_CHILD_LEVELS,
  SET_SOH_DYNAMIC_HIERARCHIES,
  SET_SOH_DYNAMIC_HIERARCHIES_UPDATED,
  SET_SOH_DYNAMIC_PREV_VALUE,
  SET_BUSINESS_UNIT,
  SET_PAGE_REFRESH,
  SET_SOH_DYNAMIC_EXISTING_EDIT_VALUE,
} from "../actionTypes/commonTypes";
import { dynamicPartnerTypeDropdown } from "../../utility/helper";

const INITIAL_STATE: any = {
  countryCode: null,
  countryName: null,
  currencyCode: null,
  currencyName: null,
  isLoader: false,
  geoLevel1List: [],
  errorMessage: "",
  geographicFields: [],
  levelsName: [],
  partnerType: [],
  roleHierarchy: [],
  retailerPartner: {},
  partnerTypesOptions: [],
  partnerTypeDefault: {},
  sohDatas: {},
  mappedsohChildValues: [],
  allsohSelectedValues: [],
  sohDynamicFields: [],
  sohDynamicFieldsUpdated: [],
  sohDynamicFieldsPrev: [],
  businessUnit: "",
  pageRefresh: false,
  sohExistingInitialValue: [],
};

function commonReducer(state = { INITIAL_STATE }, action: any): any {
  switch (action.type) {
    case LOADING_REQUEST: {
      return {
        ...state,
        isLoader: action.status,
      };
    }
    case GET_GEOLOCATION_LEVEL1_OPTIONS_SUCCESS: {
      return {
        ...state,
        isLoader: false,
        geoLevel1List: Object.keys(action.levels.body).length !== 0 ? action.levels.body.geolevel1 : [],
      };
    }
    case GET_GEOLOCATION_LEVEL1_OPTIONS_ERROR: {
      return {
        ...state,
        errorMessage: action.errorMsg,
      };
    }
    case SET_GEOLOCATION_LEVEL1_OPTIONS: {
      return {
        ...state,
        isLoader: false,
        geoLevel1List: action.levels,
      };
    }
    case GET_GEOLOCATION_FIELDS_SUCCESS: {
      let locationData = action?.getTemplateInfo?.locationhierarchy;
      let rolehiererchy = action?.getTemplateInfo?.rolehierarchy;
      let levels: any = [];
      let levelsNames: any = [];
      let partnerList: any = [];
      let role: any = [];
      let partners: any = {};
      //let roleList = rolehiererchy.filter((item: any) => item.hassubbranch);

      rolehiererchy?.length > 0 && rolehiererchy.forEach((item: any) => {
        let roleValues = { label: item?.rolehierarchyname, value: item?.rolename };
        role.push(roleValues);
        if (item.rolename === "RETAILER" || item.rolename === "DISTRIBUTOR") {
          let roleObj = { label: item?.rolehierarchyname, value: item?.rolename };
          partnerList.push(roleObj);
        }
      });
      // To check retailer existence if yes keeping retailer as default partner type else first value as default in dropdown
      const isRetailer = partnerList?.find((item: any) => item.value === "RETAILER");
      if (isRetailer !== undefined) {
        partners = {};
        const data = { type: isRetailer.value, name: isRetailer.label };
        Object.assign(partners, data);
      } else {
        if(partnerList?.length){
          const data = { type: partnerList[0].value, name: partnerList[0].label };
          Object.assign(partners, data);
        }
       
      }
      locationData?.length>0 &&locationData.forEach((item: any) => {
        levelsNames.push(item.name.toLowerCase());
        let locationhierlevel = item.level;
        let geolevels = "geolevel" + locationhierlevel;
        levels.push(geolevels);
      });
      const partnerListAsc: any = partnerList?.sort(function (a: any, b: any) {
        let fa = a.label.toLowerCase(),
          fb = b.label.toLowerCase();
        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });
      return {
        ...state,
        geographicFields: levels,
        levelsName: levelsNames,
        partnerType: partnerListAsc,
        roleHierarchy: rolehiererchy,
        retailerPartner: partners,
      };
    }
    case GET_GEOLOCATION_FIELDS_ERROR: {
      return {
        ...state,
        errorMessage: action.errorMsg,
      };
    }
    case GET_ALL_PARTNER_TYPES: {
      let final = {};
      const scantypes = action.partnerType?.map((ele: any) => ele?.allowedscantypes);
      let scantypesNew = action?.partnerType?.map((ele: any) => {
        return {
          allowedscantypes: ele?.allowedscantypes,
          scantypetorole: ele?.scantypetorole,
        };
      });
      let role = action?.roleHierarchy?.map((ele: any) => {
        return {
          rolename: ele?.rolename,
          rolehierarchyname: ele?.rolehierarchyname,
        };
      });

      if (scantypes?.includes("SCAN_OUT_W2D") && scantypes?.includes("SCAN_OUT_W2R")) {
        let val: any = dynamicPartnerTypeDropdown("both", role, scantypesNew);

        final = {
          ...state,
          partnerTypesOptions: val?.partnerTypesOptions,
          partnerTypeDefault: val?.partnerTypeDefault,
        };
      } else if (scantypes?.includes("SCAN_OUT_W2D")) {
        let val: any = dynamicPartnerTypeDropdown("SCAN_OUT_W2D", role, scantypesNew);

        final = {
          ...state,
          partnerTypesOptions: val?.partnerTypesOptions,
          partnerTypeDefault: val?.partnerTypeDefault,
        };
      } else if (scantypes?.includes("SCAN_OUT_W2R")) {
        let val: any = dynamicPartnerTypeDropdown("SCAN_OUT_W2R", role, scantypesNew);

        final = {
          ...state,
          partnerTypesOptions: val?.partnerTypesOptions,
          partnerTypeDefault: val?.partnerTypeDefault,
        };
      } else {
        final = {
          ...state,
        };
      }

      return final;
    }
    case GET_SOH_DETAILS_SUCCESS: {
      let sohDetails = action.payload;
      return {
        ...state,
        sohDatas: sohDetails,
      };
    }

    case SET_SELECTED_MAPPED_SOH_CHILD_LEVELS: {
      return {
        ...state,
        mappedsohChildValues: action.data,
      };
    }

    case SET_ALL_SOHLEVEL_SELECTED_VALUES: {
      console.log("reducer", action.data);
      return {
        ...state,
        allsohSelectedValues: action.data,
      };
    }

    case SET_SOH_DYNAMIC_HIERARCHIES: {
      return {
        ...state,
        sohDynamicFields: action.data,
      };
    }

    case SET_SOH_DYNAMIC_HIERARCHIES_UPDATED: {
      console.log("action.data", action.data);
      return {
        ...state,
        sohDynamicFieldsUpdated: action.data,
      };
    }
    case SET_SOH_DYNAMIC_PREV_VALUE: {
      return {
        ...state,
        sohDynamicFieldsPrev: action.data,
      };
    }
    case SET_BUSINESS_UNIT: {
      return {
        ...state,
        businessUnit: action.data,
      };
    }
    case SET_PAGE_REFRESH: {
      return {
        ...state,
        pageRefresh: action.data,
      };
    }
    case SET_SOH_DYNAMIC_EXISTING_EDIT_VALUE: {
      return {
        ...state,
        sohExistingInitialValue: action.data,
      };
    }

    default:
      return state;
  }
}

export default commonReducer;
