export const GET_OVERALL_CONSOLIDATED_SCANS_SUCCESS = 'DEV_CONFIG_LOCATION_ADD_INPUTLIST';
export const GET_OVERALL_CONSOLIDATED_SCANS_ERROR = 'DEV_CONFIG_LOCATION_ADD_ERROR';
export const LOADING_REQUEST = 'LOADING_REQUEST';
export const GET_BRANDWISE_SCANS_SUCCESS = 'GET_BRANDWISE_SCANS_SUCCESS';
export const GET_BRANDWISE_SCANS_ERROR = 'GET_BRANDWISE_SCANS_ERROR';
export const SET_SELECTED_BRANDS = 'SET_SELECTED_BRANDS';
export const GET_PRODUCTWISE_SCANS_SUCCESS = 'GET_PRODUCTWISE_SCANS_SUCCESS';
export const GET_PRODUCTWISE_SCANS_ERROR = 'GET_PRODUCTWISE_SCANS_ERROR';
export const SET_SELECTED_PRODUCTS = 'SET_SELECTED_PRODUCTS';
export const SET_OVERALL_SCANS = 'SET_OVERALL_SCANS';
export const DOWNLOAD_CSV_FILE = 'DOWNLOAD_CSV_FILE';

