export const GET_OVERALL_INVENTORY_SUCCESS = 'GET_OVERALL_INVENTORY_SUCCESS';
export const GET_OVERALL_INVENTORY_SUCCESS_TOTAL = 'GET_OVERALL_INVENTORY_SUCCESS_TOTAL';
export const GET_OVERALL_INVENTORY_ERROR = 'GET_OVERALL_INVENTORY_ERROR';
export const LOADING_REQUEST = 'LOADING_REQUEST';
export const GET_BRANDWISE_INVENTORY_SUCCESS = 'GET_BRANDWISE_INVENTORY_SUCCESS';
export const GET_BRANDWISE_INVENTORY_ERROR = 'GET_BRANDWISE_INVENTORY_ERROR';
export const SET_SELECTED_BRANDSWISEINVENTORY = 'SET_SELECTED_BRANDSWISEINVENTORY';
export const GET_PRODUCTWISE_INVENTORY_SUCCESS = 'GET_PRODUCTWISE_INVENTORY_SUCCESS';
export const GET_PRODUCTWISE_INVENTORY_ERROR = 'GET_PRODUCTWISE_INVENTORY_ERROR';
export const SET_SELECTED_PRODUCTSWISEINVENTORY = 'SET_SELECTED_PRODUCTSWISEINVENTORY';
export const SET_OVERALL_INVENTORY = 'SET_OVERALL_INVENTORY';
export const DOWNLOAD_INVENTORY_CSV_FILE = 'DOWNLOAD_INVENTORY_CSV_FILE';

