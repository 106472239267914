import { combineReducers } from "redux";
import devconfigReducer from "./devconfig";
import commonReducer from "./common";
import consolidatedScansReducer from "./consolidatedScans";
import InventoryReducer from "./inventory";
import userCreationReducer from "./userCreation";
import { DEFAULT_STATE, DEFAULT_STATE_FF_EF, FULFILLED, PENDING, REJECTED } from "../../utility/constant";
import { GET_CURRENT_COUNTRY, GET_LOOKUP } from "../actionTypes/commonTypes";

/**
 * Method is to create reducer function dynamically
 * @param {*} reducerName
 * @param {*} defaultStateParam
 */
const createReducer = (reducerName: any, defaultStateParam: any) => {
  return (state: any, action: any) => {
    state = state || defaultStateParam || DEFAULT_STATE;
    switch (action.type) {
      case `${reducerName}_${PENDING}`:
      case `${reducerName}_${FULFILLED}`:
      case `${reducerName}_${REJECTED}`:
        return Object.assign({}, action.payload);
      default:
        return state;
    }
  };
};

const rootReducer = combineReducers({
  devconfig: devconfigReducer,
  common: commonReducer,
  consolidatedScans: consolidatedScansReducer,
  inventory: InventoryReducer,
  lookup: createReducer(GET_LOOKUP, DEFAULT_STATE_FF_EF),
  loggedUserCountry: createReducer(GET_CURRENT_COUNTRY, DEFAULT_STATE_FF_EF),
  userCreation: userCreationReducer,
});

export default rootReducer;
