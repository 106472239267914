/* eslint-disable array-callback-return */
import { Alert } from "./widgets/toaster";
import moment from "moment";
import { getLocalStorageData } from "../utility/base/localStore";
import { FormattedMessage } from "react-intl";
import _ from "lodash";
import { configApp } from "./base/utils/config";
import Authorization from "../utility/authorization";

const sessionDefaultTime: number = 30;

let oneTimeCall = false;

let userDatas: any = getLocalStorageData("userData");
let userData = JSON.parse(userDatas);

/**
 * Download excel file
 * @param tableId
 * @param fileName
 */
export const downloadExcel = (tableId: any, fileName: string) => {
  let excelFileName = "excel_table_data";
  let TableDataType = "application/vnd.ms-excel";
  let selectTable: any = document.getElementById(tableId);
  let htmlTable = selectTable.outerHTML.replace(/ /g, "%20");

  fileName = fileName ? fileName + ".xls" : excelFileName + ".xls";
  var excelFileURL = document.createElement("a");
  document.body.appendChild(excelFileURL);

  if (navigator.msSaveOrOpenBlob) {
    var blob = new Blob(["\ufeff", htmlTable], {
      type: TableDataType,
    });
    navigator.msSaveOrOpenBlob(blob, fileName);
  } else {
    excelFileURL.href = "data:" + TableDataType + ", " + htmlTable;
    excelFileURL.download = fileName;
    excelFileURL.click();
  }
};

/**
 * To download the csv file format
 * @param csv
 * @param filename
 */
export const downloadCsvFile = (csv: any, filename: string) => {
  if (csv?.status === 404 || !csv) {
    const noDataMessage = <FormattedMessage id="toast.noData" />;
    Alert("warning", noDataMessage);
  } else if (csv?.status === 500) {
    let error = { message: csv?.message_response };
    ErrorMsg(error);
  } else {
    var csvFile;
    var downloadLink;

    var BOM = "\uFEFF";
    csv = BOM + csv;

    // CSV FILE
    csvFile = new Blob([csv], { type: "text/csv;charset=utf-8" });
    let DateAndTime = moment(new Date()).format("MM_DD_YYYY");

    // Download link
    downloadLink = document.createElement("a");

    // File name
    downloadLink.download = `${filename + "_" + DateAndTime}.csv`;

    // We have to create a link to the file
    downloadLink.href = window.URL.createObjectURL(csvFile);

    // Make sure that the link is not displayed
    downloadLink.style.display = "none";

    // Add the link to your DOM
    document.body.appendChild(downloadLink);

    // Lanzamos
    downloadLink.click();
    const successMessage = <FormattedMessage id="toast.success" />;
    Alert("success", successMessage);
  }
};

export const downloadCsvFileOld = (csv: any, filename: string) => {
  if (csv?.status === 404 || !csv) {
    const noDataMessage = <FormattedMessage id="toast.noData" />;
    Alert("warning", noDataMessage);
  } else if (csv?.status === 500) {
    let error = { message: csv?.message_response };
    ErrorMsg(error);
  } else {
    //var csvFile;
    var downloadLink;

    //Create anchor tag
    downloadLink = document.createElement("a");
    let DateAndTime = moment(new Date()).format("MM_DD_YYYY");

    // File name
    downloadLink.download = `${filename + "_" + DateAndTime}.csv`;

    //link to download
    let link = configApp.env + "static/" + csv.data;

    // We have to create a link to the file
    downloadLink.href = link;

    // // Make sure that the link is not displayed
    downloadLink.style.display = "none";

    // Add the link to your DOM
    document.body.appendChild(downloadLink);

    downloadLink.click();
    const successMessage = <FormattedMessage id="toast.donloadSuccess" />;
    Alert("downloadComplete", successMessage);
  }
};

export const isValidDate = (date: any) => {
  if (!isNaN(Date.parse(date))) {
    return true;
  } else {
    return false;
  }
};

// function objectValues<T extends {}>(obj: T) {
//   return Object.keys(obj).map((objKey) => obj[objKey as keyof T]);
// }

// function objectKeys<T extends {}>(obj: T) {
//   return Object.keys(obj).map((objKey) => objKey as keyof T);
// }

export const hasDuplicate = (array: Array<any>, key: string) => {
  return true;
};

export const handledropdownoption = (array: Array<any>, key: string) => {
  const data: any =
    array?.length > 0 &&
    array.map((val: any) => {
      return { value: val[key], text: val[key] };
    });
  return data;
};
export const optionList = (array: Array<any>, value: string, label: string) => {
  const data: any =
    array?.length > 0 &&
    array.map((val: any) => {
      return { value: val[value], text: val[label] };
    });
  return data;
};
export const ErrorMsg = (error: any) => {
  let msg = error ? error?.message : <FormattedMessage id="toast.wrong" />;
  Alert("error", msg);
};

// SessionTimeout validation
export const checkSessionTimeOut = () => {
  let data: any = getLocalStorageData("sessionTime");
  let login_time = data;

  let timeStamp = moment.unix(login_time).format("D/M/YYYY hh:mm:ss");
  let now = moment().format("D/M/YYYY hh:mm:ss");
  // let duration = moment(now).diff(moment(timeStamp), 'minute')
  let duration: any = moment(now, "DD/MM/YYYY HH:mm:ss").diff(moment(timeStamp, "DD/MM/YYYY HH:mm:ss"), "minute");
  if (duration < sessionDefaultTime) {
    return true;
  }
  const idealMessage = <FormattedMessage id="toast.ideal" />;
  if (!oneTimeCall) Alert("warning", idealMessage);
  oneTimeCall = true;
  return false;
};

export const accessDeniedToaster = () => {
  const message = <FormattedMessage id="toast.denied" />;
  return Alert("error", message);
};
export const isObject = (value: any) => {
  return value && typeof value === "object" && value.constructor === Object;
};
/**
 * Get Geo level One name
 * @param data
 * @param geolevel1
 * @returns
 */
export const getGeoLevelOneName = (data: Array<any>, geolevel1: string) => {
  if (data?.length > 0) {
    const obj = data.find((level: any) => level?.geolevels === geolevel1);
    return obj?.name || "";
  }
};

/**
 * This method handle the rolename fields
 * @param name
 * @param data
 * @returns
 */
export const displayRoleHiearchyName = (name: string, data: Array<any>) => {
  const roleName: any = data?.length > 0 && data.find((role: any) => role?.rolename === name?.toLocaleUpperCase());
  if (roleName?.rolehiername) {
    return _.startCase(_.toLower(roleName.rolehiername));
  } else return "NA";
};
/**
 * This method handle typeof value
 * @param type
 * @param value
 * @param optional
 * @returns
 */

export const checkTypeAndValue = (type: any, value: any, optional?: any) => {
  if (typeof value === type) {
    return value;
  } else {
    return "";
  }
};

/**
 * This method removed the null value object from array
 * @param data
 * @param valueKey
 * @param labelKey
 * @param optionalKey
 * @returns
 */
export const removedNullObjFromArray = (data: Array<any>, valueKey: string, labelKey: string, optionalKey?: any) => {
  let temp = [];
  if (data?.length > 0) {
    for (let i = 0; i < data.length; i++) {
      if (data[i][valueKey] && data[i][labelKey]) {
        let obj = { value: data[i][valueKey], label: data[i][labelKey] };
        if (optionalKey && Object.keys(optionalKey).length > 0) {
          obj = { ...obj, [optionalKey?.key1]: data[i][optionalKey?.key2] };
        }
        temp.push({ ...obj });
      }
    }
  }
  return temp;
};

/**
 * This method will return the selected geolevels in filters -For Admin(1st 2 levels) and for RSM's(All SOH Geo Levels )
 * @param userData
 * @param selectedAdminGeoFilters
 * @param selectedOtherGeoFilters
 * @param sohgeolevel
 * @param lastGeolevelName
 * @param sohlevel
 * @param sohLastLevel
 * @returns
 */
export const sohSelectedGeoLevels = (
  userData: any,
  selectedAdminGeoFilters: any,
  selectedOtherGeoFilters: any,
  sohgeolevel: any,
  lastGeolevelName: any,
  sohlevel: any,
  sohLastLevel: any
) => {
  let geolevelFilters = {};
  if (userData?.role === "ADMIN") {
    let { geolevel1code, geolevel2code }: any = selectedAdminGeoFilters;
    geolevelFilters = {
      geolevel1code: geolevel1code === "ALL" ? null : geolevel1code,
      geolevel2code: geolevel2code === "ALL" ? null : geolevel2code,
    };
  } else {
    geolevelFilters = {
      [sohgeolevel + "code"]:
        selectedOtherGeoFilters["geolevel" + sohlevel + "code"] === "ALL"
          ? null
          : selectedOtherGeoFilters["geolevel" + sohlevel + "code"],
      [lastGeolevelName + "code"]:
        selectedOtherGeoFilters["geolevel" + sohLastLevel + "code"] === "ALL"
          ? null
          : selectedOtherGeoFilters["geolevel" + sohLastLevel + "code"],
    };
  }
  return geolevelFilters;
};

/** To get sohGeolevel for particular user **/
export const getSohGeoLevel = () => {
  let obj: any = getLocalStorageData("userData");
  let userData = JSON.parse(obj);
  const sohgeolevel = userData?.sohgeolevel;
  // const sohgeolevel = "geolevel4";
  let sohlevel = sohgeolevel?.charAt(sohgeolevel.length - 1);
  return sohlevel;
};

/** To check whether loggedIn user is associated with multiple location **/
export const isMultiLocationAssociated = () => {
  let sohlevel = getSohGeoLevel();
  let userDatas: any = getLocalStorageData("userData");
  let userData = JSON.parse(userDatas);
  let geolevelcode = userData?.["geolevel" + sohlevel + "code"];
  // let geolevelcode = "031404,031402";
  let isMultipleRegionAssociated = geolevelcode?.includes(",");
  return isMultipleRegionAssociated;
};

/**To generate options for particular soh level if user associated with multiple location  **/
export const generateOptionsForSOHMultipleLocation = () => {
  let sohlevel = getSohGeoLevel();
  let userDatas: any = getLocalStorageData("userData");
  let userData = JSON.parse(userDatas);
  let geo = userData?.["geolevel" + sohlevel];
  let geolevelcode = userData?.["geolevel" + sohlevel + "code"];
  // let geo = "Lunzu,Kunthembwe";
  // let geolevelcode = "031404,031402";
  let isMultipleRegionAssociated = geolevelcode?.includes(",");
  let multipleLocationCodes: any = [];
  let multipleLocationNames: any = [];
  if (isMultipleRegionAssociated) {
    multipleLocationCodes = geolevelcode.split(",");
    multipleLocationNames = geo.split(",");
  }
  let allOptions = {
    label: "ALL",
    value: "ALL",
    code: "ALL",
  };
  let multipleLocationOptions: any = [];
  multipleLocationOptions.push(allOptions);
  if (isMultiLocationAssociated()) {
    multipleLocationNames.forEach((item: any, index: number) => {
      let multiplelevelInfo = {
        label: item,
        value: item,
        code: multipleLocationCodes[index],
      };
      multipleLocationOptions.push(multiplelevelInfo);
    });
  }

  return multipleLocationOptions;
};

// To check whether the code is in partial areas
export const isSelectedCodePartial = (selectedCode: any) => {
  let excludesohgeolevelcodes = userData?.excludesohgeolevelcodes;
  // excludesohgeolevelcodes = "023108,023102";
  let isPartial = "";
  if (excludesohgeolevelcodes !== null) {
    let excludesohgeolevelcodesArr = excludesohgeolevelcodes?.split(",");
    isPartial =
      excludesohgeolevelcodesArr?.length > 0 && excludesohgeolevelcodesArr.find((item: any) => item === selectedCode);
  }
  return isPartial;
};

/**
 * Handle first letter is upper case of each sentence
 * @param str
 * @returns
 */
export const toTitleCase = (str: any) => {
  return str?.replace(/(\w*\W*|\w*)\s*/g, function (txt: any) {
    return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
  });
};

/**
 * This method handle to check one key value is truthy(valid) in dataList
 * @param dataList
 * @param keysList
 * @returns
 */
export const truthyValues = (dataList: Array<any>, keysList: Array<string>) => {
  let result = [];
  if (dataList?.length && keysList.length) {
    for (let i = 0; i < dataList.length; i++) {
      let cond = false;
      for (let j = 0; j < keysList.length; j++) {
        if (dataList[i][keysList[j]] !== "0" && dataList[i][keysList[j]]) {
          cond = true;
        }
      }
      if (cond) {
        //If one key value is valid and update list(not checked all value)
        result.push(dataList[i]);
      }
    }
  }
  return result;
};

/**
 * If getting ALL value in dropdown option and replace the null value
 * @param filters
 */
export const assignedNull = (filters: any) => {
  let obj = {};
  for (let key in filters) {
    obj = {
      ...obj,
      [key]: filters[key] === "ALL" ? null : filters[key],
    };
  }
  return { ...obj };
};

/**
 * Convert first letter is uppercase in string
 * @param str
 * @returns
 */
export const firstLetterUpper = (str: any) => {
  //split the above string into an array of strings
  //whenever a blank space is encountered
  if (str) {
    str = str.toString().toLowerCase();
    const arr = str?.split(" ");

    //loop through each element of the array and capitalize the first letter.

    for (let i = 0; i < arr?.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }

    //Join all the elements of the array back into a string
    //using a blankspace as a separator
    const result = arr.join(" ");
    return result;
  } else {
    return "";
  }
};

//To allow only Numbers and only 3 digits
export const allowOnlyNumbers = (event: any) => {
  if (!`${event.target.value}${event.key}`.match(/^((?!(0))[0-9]{0,3})$/)) {
    event.preventDefault();
    event.stopPropagation();
    return false;
  }
};
/**
 * To allow only Numbers and custom maximum digits
 * @param event
 * @param maxDigit
 * @returns
 */
export const allowNumCusDigit = (event: any, maxDigit: number) => {
  const matcher = `^((?!(0))[0-9]{0,${maxDigit}})$`;
  const re = new RegExp(matcher);
  if (!`${event.target.value}${event.key}`.match(re)) {
    event.preventDefault();
    event.stopPropagation();
    return false;
  }
};

export const downloadTemplateFile = (downloadURL: any, downloadName: string, params?: any) => {
  let URL = configApp.env + downloadURL;
  let accessToken = Authorization.getAccessToken();
  if (params?.countrycode) URL = URL + "?countrycode=" + params?.countrycode;
  fetch(URL, {
    method: "GET",
    headers: {
      "Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then((response) => response.blob())
    .then((blob) => {
      // Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]));

      let DateAndTime = moment(new Date()).format("MM_DD_YYYY");

      const link: any = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${downloadName + "_" + DateAndTime}.xlsx`);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    });
};

/**
 * function that will return the partner type values
 *
 * @return object
 */

export const dynamicPartnerTypeDropdown = (flag: any, role: any, scantypesNew: any) => {
  let partnerTypesOptions: any = [];
  let partnerTypeDefault: any = {};
  if (flag === "both") {
    let scantype = scantypesNew.filter((ele: any) => {
      return ele.allowedscantypes === "SCAN_OUT_W2D" || ele.allowedscantypes === "SCAN_OUT_W2R";
    });
    const types = scantype?.map((ele: any) => ele?.scantypetorole);
    const webListFinal = _.uniqBy(role, "rolehierarchyname");
    let options: any = webListFinal?.filter((ele: any) => {
      if (types?.includes("DISTRIBUTOR") && types?.includes("RETAILER")) {
        return ele?.rolename === "DISTRIBUTOR" || ele?.rolename === "RETAILER";
      }
    });
    partnerTypesOptions = options?.map((ele: any) => {
      return {
        value: ele?.rolehierarchyname,
        label: ele?.rolehierarchyname,
      };
    });
    let partDef: any = options?.map((ele: any) => {
      if (ele?.rolename === "RETAILER") {
        return {
          type: ele?.rolehierarchyname,
          name: ele?.rolehierarchyname,
        };
      }
    });
    let partDefFinal = _.compact(partDef);
    partnerTypeDefault = partDefFinal[0];
    return {
      partnerTypesOptions,
      partnerTypeDefault,
    };
  } else if (flag === "SCAN_OUT_W2D") {
    let scantype = scantypesNew?.filter((ele: any) => {
      return ele?.allowedscantypes === "SCAN_OUT_W2D";
    });
    const types = scantype?.map((ele: any) => ele?.scantypetorole);
    const webListFinal = _.uniqBy(role, "rolehierarchyname");
    let options: any = webListFinal?.filter((ele: any) => {
      if (types?.includes("DISTRIBUTOR")) {
        return ele?.rolename === "DISTRIBUTOR";
      }
    });
    partnerTypesOptions = options?.map((ele: any) => {
      return {
        value: ele?.rolehierarchyname,
        label: ele?.rolehierarchyname,
      };
    });
    let partDef: any = options?.map((ele: any) => {
      return {
        type: ele?.rolehierarchyname,
        name: ele?.rolehierarchyname,
      };
    });
    partnerTypeDefault = partDef[0];
    return {
      partnerTypesOptions,
      partnerTypeDefault,
    };
  } else if (flag === "SCAN_OUT_W2R") {
    let scantype = scantypesNew?.filter((ele: any) => {
      return ele?.allowedscantypes === "SCAN_OUT_W2R";
    });
    const types = scantype?.map((ele: any) => ele?.scantypetorole);
    const webListFinal = _.uniqBy(role, "rolehierarchyname");
    let options: any = webListFinal?.filter((ele: any) => {
      if (types?.includes("RETAILER")) {
        return ele?.rolename === "RETAILER";
      }
    });
    partnerTypesOptions = options?.map((ele: any) => {
      return {
        value: ele?.rolehierarchyname,
        label: ele?.rolehierarchyname,
      };
    });
    let partDef: any = options?.map((ele: any) => {
      return {
        type: ele?.rolehierarchyname,
        name: ele?.rolehierarchyname,
      };
    });
    partnerTypeDefault = partDef[0];
    return {
      partnerTypesOptions,
      partnerTypeDefault,
    };
  }
};

// To download the file in csv sheet
export const downloadCsvFileNew = (csv: any, filename: string) => {
  let time = moment(new Date()).format("HH:mm:ss");
  if (csv?.code === 404 || !csv) {
    const noDataMessage = <FormattedMessage id="toast.noData" />;
    Alert("warning", noDataMessage);
  } else if (csv?.status === 500) {
    let error = { message: csv?.message_response };
    ErrorMsg(error);
  } else {
    let link = configApp.env + "static/" + csv.data;
    let accessToken = Authorization.getAccessToken();
    fetch(link, {
      method: "GET",
      headers: {
        // "Content-Type": "text/csv",
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));

        let DateAndTime = moment(new Date()).format("MM_DD_YYYY");

        const link: any = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${filename + "_" + DateAndTime}.csv`);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
        const successMessage = `Thank you for your patience, your ${filename} data requested at ${time} is being downloaded.`;
        if (filename !== "Download_Template") {
          Alert("downloadComplete", successMessage);
        }
      })
      .catch((error) => {
        if (error.message) {
          const noDataMessage = <FormattedMessage id="toast.noData" />;
          Alert("warning", noDataMessage);
        }
      });
  }
};

// To download the file in xlsx sheet
export const downloadxlsxFile = (xls: any, filename: string) => {
  let time = moment(new Date()).format("HH:mm:ss");
  if (xls?.code === 404 || !xls) {
    const noDataMessage = <FormattedMessage id="toast.noData" />;
    Alert("warning", noDataMessage);
  } else if (xls?.status === 500) {
    let error = { message: xls?.message_response };
    ErrorMsg(error);
  } else {
    let Link = configApp?.envAuth?.replace("oauth/token", "") + "static/" + xls.data;
    let accessToken = Authorization.getAccessToken();
    let headers = new Headers();
    headers.append("Content-Type", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
    headers.append("Authorization", `Bearer ${accessToken}`);
    fetch(Link, {
      method: "GET",
      headers,
    })
      .then((response) => {
        let DateAndTime = moment(new Date()).format("MM_DD_YYYY");
        // Creating a A tag for downloading the excel
        const link: any = document.createElement("a");
        link.href = response.url;
        setTimeout(() => {
          link.setAttribute("download", `${filename + "_" + DateAndTime + "_" + time}.xlsx`);
          // Append to html link element page
          document.body.appendChild(link);
          // Start download
          link.click();
          // Clean up and remove the link
          link.parentNode.removeChild(link);
          const successMessage = `Thank you for your patience, your ${filename} data requested at ${time} is being downloaded.`;
          if (filename !== "Download_Template") {
            Alert("downloadComplete", successMessage);
          }
        }, 8000);
      })
      .catch((error) => {
        if (error.message) {
          const noDataMessage = <FormattedMessage id="toast.noData" />;
          Alert("warning", noDataMessage);
        }
      });
  }
};

export const getSohDetails = (geoKey?: any) => {
  const sohParam = "sohids";
  let userDatas: any = getLocalStorageData("userData");
  let userData = JSON.parse(userDatas);
  const sohIds = userData?.sohids;
  const sohCodes = userData?.sohcodes;
  let result;
  let condName = geoKey ? geoKey + sohParam : sohParam;
  if (sohParam === "sohids") {
    result = { [condName]: sohIds };
  } else {
    result = { sohcodes: sohCodes };
  }
  return result;
};

/**
 * This method get sohids and sohcodes from lowestsohlevel of Roles
 * @param data
 * @returns
 */
export const getSOHIdsAndCode = (data: any[]) => {
  const ids: any[] = [];
  const code: any[] = [];
  data?.length > 0 &&
    data.forEach((soh: any) => {
      soh?.sohid && ids.push(soh?.sohid);
      soh?.sohcode && code.push(soh?.sohcode);
    });
  const sohids: any = ids?.length ? ids.join(",") : "";
  const sohcodes: any = code?.length ? code.join(",") : "";
  return { sohids, sohcodes };
};

// This method is to get all Features
export const getFeatures = () => {
  let userData = Authorization.getAuthUser();
  let availableFeature: any = userData?.features;
  if (availableFeature && Object.keys(availableFeature)?.length) {
    const { countrycode, roledesc, rolename, ...featureValue }: any = availableFeature;
    const features: any = featureValue && Object.keys(featureValue).filter((e) => featureValue[e]);
    return features;
  }
};
