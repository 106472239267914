import React, { Component } from "react";
import logo from "../../assets/icons/bayer_logo.svg";
import menuIcon from "../../assets/icons/menu_icon.svg";
import "../../assets/scss/layout.scss";
import { getLocalStorageData } from "../../utility/base/localStore";
import BayerRewardsImg from "../../assets/icons/brp_logo.svg";
import { CircleFlag } from "react-circle-flags";
import Authorization from "../../utility/authorization";
import { AppContext } from "../context";
import _ from "lodash";
import { getLoggedUserCountryInfo } from "../../redux/actions";
import { connect } from "react-redux";
import { ErrorMsg, toTitleCase } from "../../utility/helper";
import Avatar from "@material-ui/core/Avatar";
import SOHEDIT from "../../assets/images/soh_edit.svg";
import { Link } from "react-router-dom";

type Props = {
  history?: any;
  currentLocale?: any;
  handleChange?: any;
  dispatch: any;
  country: any;
};
type States = {
  dropdownOpenprofile: boolean;
  dropdownOpenNotification: boolean;
  userData: any;
  loggedUserInfo: any;
};
// const availableLanguages = [
//   {
//     key: "en",
//     value: "ENGLISH",
//   },
//   {
//     key: "es",
//     value: "SPANISH",
//   },
//   {
//     key: "fr",
//     value: "FRENCH",
//   },
// ];

class TopBar extends Component<Props, States> {
  static contextType = AppContext;
  constructor(props: any) {
    super(props);
    this.state = {
      dropdownOpenprofile: false,
      dropdownOpenNotification: false,
      userData: "",
      loggedUserInfo: {},
    };
  }
  componentDidMount() {
    const data: any = getLocalStorageData("userData");
    if (data) {
      this.setState({
        userData: JSON.parse(data),
      });
      this.props.dispatch(getLoggedUserCountryInfo());
    }
    // const loggedUserCountry = Authorization.getTemplateCountry();
    // if (!_.isEmpty(loggedUserCountry)) {
    //   const sohgeolevel = userData?.sohgeolevel;
    //   const sohlevel = sohgeolevel.charAt(sohgeolevel.length - 1);
    //   const geoObj =
    //     loggedUserCountry?.locationhierarchy?.length &&
    //     loggedUserCountry.locationhierarchy.find((value: any) => value.level.toString() === sohlevel.toString());

    //   let obj = {
    //     country: loggedUserCountry?.country,
    //     countrycode: loggedUserCountry?.countrycode,
    //     currency: loggedUserCountry?.currency,
    //     currencycode: loggedUserCountry?.currencycode,
    //     geolevName: geoObj?.name,
    //   };
    //   this.setState({
    //     loggedUserInfo: { ...obj },
    //   });
    // }
  }

  toggleprofile = () => {
    this.setState((prevState) => ({
      dropdownOpenprofile: !prevState.dropdownOpenprofile,
    }));
  };
  toggleNotofication = () => {
    this.setState((prevState) => ({
      dropdownOpenNotification: !prevState.dropdownOpenNotification,
    }));
  };
  /**
   * To logout page and check the unsaved change value for Prompt
   * @param value
   */
  handleChange = (value: any) => {
    //accessed context api values are given
    const { promptMode } = this.context;
    this.props.history.push("/landing");
    if (!promptMode) {
      Authorization.logOut();
    }
  };
  /**
   * Check and display the error or success message
   * @param {*} nextProps
   */
  shouldComponentUpdate(nextProps: any) {
    const { country } = this.props;
    const { userData } = this.state;
    // Assign all fields to state
    if (
      !nextProps.country.isFetching &&
      !nextProps.country.isError &&
      !_.isEqual(country?.response?.body, nextProps.country?.response?.body)
    ) {
      const data = nextProps?.country?.response?.body;
      if (data?.length > 0) {
        const sohgeolevel = userData?.sohgeolevel;
        const sohlevel = sohgeolevel.charAt(sohgeolevel.length - 1);
        const geoObj =
          data[0]?.locationhierarchy?.length &&
          data[0].locationhierarchy.find((value: any) => value.level.toString() === sohlevel.toString());

        let obj = {
          country: data[0]?.country,
          countrycode: data[0]?.countrycode,
          currency: data[0]?.currency,
          currencycode: data[0]?.currencycode,
          geolevName: geoObj?.name,
        };
        this.setState({
          loggedUserInfo: { ...obj },
        });
      }
    } else if (!nextProps.country.isFetching && nextProps.country.isError) {
      const error = nextProps?.country?.response;
      ErrorMsg(error);
    }

    return true;
  }
  render() {
    const { loggedUserInfo, userData } = this.state;
    const LoggedInCountryFlag = _.lowerCase(userData?.countrycode);
    //let flagName: any;
    // if (userData.countrycode === "MW") {
    //   flagName = MalawiFlag;
    // } else if (userData.countrycode === "CI") {
    //   flagName = ciflag;
    // } else if (userData.countrycode === "IN") {
    //   flagName = IndiaFLag;
    // } else if (userData.countrycode === "KE") {
    //   flagName = KEflag;
    // } else if (userData.countrycode === "BD") {
    //   flagName = BDflag;
    // }
    const countryName = process.env.REACT_APP_STAGE !== "prod" ? loggedUserInfo?.country : "";
    const constituencyCount = userData[userData.sohgeolevel]?.split(",").length;
    const isMultiConstituency = constituencyCount > 1 ? true : false;
    //const isCLoseSOHEdit = Authorization.isSOHUser()?getLocalStorageData("isOneSOHAllLevel") ? true : false :true;
    const loggedUserSoh = Authorization.getSOHList();
    const isCLoseSOHEdit = userData?.username
      ? Authorization.isRSMAdmin()
        ? true
        : getLocalStorageData("isOneSOHAllLevel")
        ? true
        : false
      : true;
    return (
      <div className="topbar">
        <div className="topbar-left">
          <div className="logo">
            <span>
              <img src={BayerRewardsImg} alt="Logo" height="55" />
            </span>
            <i>
              <img src={BayerRewardsImg} alt="Logo" height="27" />
            </i>
          </div>
        </div>

        <nav className="navbar-custom">
          <ul className="list-inline menu-left mb-0">
            <li className="float-left center button-menu-mobile open-left waves-effect">
              <img src={menuIcon} alt="Logo" />
              {/* {countryName && <span className="country-name">BRP {countryName}</span>} */}
            </li>
          </ul>

          <ul className="navbar-right d-flex list-inline float-right mb-0">
            <li className="dropdown notification-list">
              {/* <Dropdown isOpen={dropdownOpenNotification} toggle={this.toggleNotofication}>
                                <DropdownToggle className="nav-link dropdown-toggle testflag arrow-none waves-effect" tag="a">
                                  
                                    <img src={bell} alt="User" width="20" />
                                    <span className="badge badge-pill badge-danger noti-icon-badge">1</span>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu dropdown-menu-right dropdown-menu-lg">
                                    <h6 className="dropdown-item-text">
                                        Notifications (1)
                                </h6>
                                    <DropdownItem >
                                        <Link id="ex" to="#" className="dropdown-item text-center text-success">
                                            Scanned successfully <i className="fi-arrow-right"></i>
                                        </Link>
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown> */}
            </li>
            {/* <div class="select-language">
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={this.props.currentLocale}
                onChange={(e: any) => {
                  this.props.handleChange(e);
                }}
                style={{ minWidth: "70%" }}
              >
                {availableLanguages.map((availableLanguages: any) => (
                  <MenuItem value={availableLanguages.key}>{availableLanguages.value}</MenuItem>
                ))}
              </Select>
            </div> */}
            <div className="profileSettings">
              <div className="flag-img">
                <CircleFlag countryCode={LoggedInCountryFlag} height={40} />
                {/* <img src={flagName} alt="user" className="rounded-circle nav-pro-img" width={50} height={40} /> */}
              </div>

              <div className="topBar-title">
                <h6 className="user-full-name">{userData?.fullname}</h6>
                {userData?.role === "ADMIN" || userData?.role === "DEVADMIN" ? (
                  <p className="role-title">
                    {userData?.role}
                    &nbsp;
                    {loggedUserSoh?.length > 1 && !isCLoseSOHEdit ? (
                      <Link to={{ pathname: "/home", state: { isCloseIcon: true } }}>
                        <img src={SOHEDIT} alt="soh-edit" height={"15"} />
                      </Link>
                    ) : null}
                  </p>
                ) : (
                  <p className="role-title multi">
                    {Authorization.isSOHUser() &&
                    userData?.lastaccessprofile &&
                    Object.keys(userData?.lastaccessprofile)?.length > 0
                      ? userData?.lastaccessprofile?.saleshiertypecode +
                        " - " +
                        userData?.lastaccessprofile?.saleshierroledescription
                      : userData?.role}
                    {!Authorization.isSOHUser() ? (
                      isMultiConstituency ? (
                        <>
                          ,&nbsp;
                          <Avatar
                            title={toTitleCase(userData[userData?.sohgeolevel])}
                            style={{
                              fontSize: "small",
                              cursor: "pointer",
                              width: "22px",
                              height: "22px",
                              color: "#ffffff",
                              backgroundColor: "rgb(3 188 251)",
                            }}
                          >
                            {userData[userData?.sohgeolevel]?.split(",").length}
                          </Avatar>
                          &nbsp;
                          {_.startCase(_.toLower(loggedUserInfo?.geolevName)) + "(s)"}
                        </>
                      ) : (
                        _.startCase(_.toLower(userData[userData?.sohgeolevel]))
                      )
                    ) : null}
                    &nbsp;
                    {!isCLoseSOHEdit ? (
                      <Link to={{ pathname: "/home", state: { isCloseIcon: true } }}>
                        <img src={SOHEDIT} alt="soh-edit" height={"15"} />
                      </Link>
                    ) : null}
                  </p>
                )}

                <p className="role-title"> {countryName && toTitleCase(countryName)}</p>
              </div>
              {/* <Dropdown isOpen={dropdownOpenprofile} toggle={this.toggleprofile}>
                <DropdownToggle className="dropdown-toggle testflag nav-link arrow-none waves-effect nav-user" tag="a">
                  <div className="profileToggle">
                    <div className="profileImg">
                      <div className="content">
                        <h4 className="title">{userData.fullname}</h4>

                        <p className="sub-title">{userData.role + ", "+ userData.geolevel1}</p> */}

              {/* <p className="sub-title">{userData.geolevel1}</p> */}
              {/* </div> */}
              {/* <img src={DropdownArrow} style={{ width: "20%", height: "20%" }} alt="" /> */}
              {/* </div>

                    <div>
                      <span className="mdi mdi-chevron-down "></span>
                    </div>
                  </div>
                </DropdownToggle> */}
              {/* <DropdownMenu>
                  <DropdownItem>
                    <i className="fa fa-user-circle"></i> <span className="ml-1">{userData.fullname}</span>
                  </DropdownItem>
                  <DropdownItem onClick={() => this.handleChange("logout")}>
                    <i className="fa fa-sign-out-alt text-danger"></i>
                    <span className="ml-1">
                      <FormattedMessage id="sideBar.logout" />
                    </span>
                  </DropdownItem>
                </DropdownMenu> */}
              {/* </Dropdown> */}
              <div style={{ paddingLeft: "20px" }}>
                <img src={logo} alt="Logo" height="40" />
              </div>
            </div>
          </ul>
        </nav>
      </div>
    );
  }
}

export default connect((state: any) => {
  return {
    country: state?.loggedUserCountry,
  };
})(TopBar);
