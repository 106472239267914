export const DEV_CONFIG_LOCATION_ADD_INPUTLIST = "DEV_CONFIG_LOCATION_ADD_INPUTLIST";
export const DEV_CONFIG_LOCATION_ADD_DPLIST = "DEV_CONFIG_LOCATION_ADD_DPLIST";
export const DEV_CONFIG_ROLE_ADD_INPUTLIST = "DEV_CONFIG_ROLE_ADD_INPUTLIST";
export const DEV_CONFIG_ROLE_ADD_DPLIST = "DEV_CONFIG_ROLE_ADD_DPLIST";
export const DEV_CONFIG_TNTFLOW_ADD_INPUTLIST = "DEV_CONFIG_TNTFLOW_ADD_INPUTLIST";
export const DEV_CONFIG_PACKAGING_DEFINITION_ADD_INPUTLIST = "DEV_CONFIG_PACKAGING_DEFINITION_ADD_INPUTLIST";

export const DEV_CONFIG_SCANPOINTS_ALLOCATION_ADD_INPUTLIST = "DEV_CONFIG_SCANPOINTS_ALLOCATION_ADD_INPUTLIST";
export const DEV_CONFIG_QRCODE_SETUP_ADD_INPUTLIST = "DEV_CONFIG_QRCODE_SETUP_ADD_INPUTLIST";
export const DEV_CONFIG_RETURNS_ALLOCATION_ADD_INPUTLIST = "DEV_CONFIG_RETURNS_ALLOCATION_ADD_INPUTLIST";
export const DEV_CONFIG_TRANSACTION_BRANCH = "DEV_CONFIG_TRANSACTION_BRANCH";
export const DEV_CONFIG_ANTI_COUNTERFEIT_SET_SMS_AUTHENTICATION = "DEV_CONFIG_ANTI_COUNTERFEIT_SET_SMS_AUTHENTICATION";
export const DEV_CONFIG_ANTI_COUNTERFEIT_SET_DIGITAL_SCAN = "DEV_CONFIG_ANTI_COUNTERFEIT_SET_DIGITAL_SCAN";
export const DEV_CONFIG_ANTI_COUNTERFEIT_SET_SMART_LABEL = "DEV_CONFIG_ANTI_COUNTERFEIT_SET_SMART_LABEL";
export const DEV_CONFIG_SET_COUNTRY_CODE = "DEV_CONFIG_SET_COUNTRY_CODE";

export const DEV_CONFIG_SET_COUNTRY_NAME = "DEV_CONFIG_SET_COUNTRY_NAME";
export const DEV_CONFIG_SET_CURRENCY_CODE = "DEV_CONFIG_SET_CURRENCY_CODE";
export const DEV_CONFIG_SET_CURRENCY_NAME = "DEV_CONFIG_SET_CURRENCY_NAME";
export const DEV_CONFIG_SET_LOCALE = "DEV_CONFIG_SET_LOCALE";
export const DEV_CONFIG_ANONYMOUS = "DEV_CONFIG_ANONYMOUS";
export const DEV_CONFIG_GPS_CAPTURE = "DEV_CONFIG_GPS_CAPTURE";
export const DEV_CONFIG_LOYALITY = "DEV_CONFIG_LOYALITY";
export const DEV_CONFIG_WINSAFE = "DEV_CONFIG_WINSAFE";
export const DEV_CONFIG_NUMBER_OF_STAFF = "DEV_CONFIG_NUMBER_OF_STAFF";
export const DEV_CONFIG_SCAN_VALIDATION = "DEV_CONFIG_SCAN_VALIDATION";
export const DEV_CONFIG_ALLOW_QRCODE_SPACE = "DEV_CONFIG_ALLOW_QRCODE_SPACE";
export const DEV_CONFIG_QRCODE_CUSTOMIZED = "DEV_CONFIG_QRCODE_CUSTOMIZED";
export const DEV_CONFIG_SET_MIN_VALUE = "DEV_CONFIG_SET_MIN_VALUE";
export const DEV_CONFIG_SET_MAX_VALUE = "DEV_CONFIG_SET_MAX_VALUE";
export const DEV_CONFIG_ACCEPTABLE_CHARS_VALUE = "DEV_CONFIG_ACCEPTABLE_CHARS_VALUE";
export const DEV_CONFIG_ADDITIONAL_CONFIG= "DEV_CONFIG_ADDITIONAL_CONFIG";
