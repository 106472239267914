import { Dispatch, AnyAction } from "redux";
import { apiURL } from "../../../utility/base/utils/config";
import { invokeGetAuthService } from "../../../utility/base/service";
import {
  LOADING_REQUEST,
  GET_GEOLOCATION_LEVEL1_OPTIONS_SUCCESS,
  GET_GEOLOCATION_LEVEL1_OPTIONS_ERROR,
  GET_GEOLOCATION_FIELDS_SUCCESS,
  GET_GEOLOCATION_FIELDS_ERROR,
  SET_GEOLOCATION_LEVEL1_OPTIONS,
  GET_LOOKUP,
  GET_CURRENT_COUNTRY,
  GET_ALL_PARTNER_TYPES,
  GET_SOH_DETAILS_SUCCESS,
  GET_SOH_DETAILS_ERROR,
  SET_SELECTED_MAPPED_SOH_CHILD_LEVELS,
  SET_ALL_SOHLEVEL_SELECTED_VALUES,
  SET_SOH_DYNAMIC_HIERARCHIES,
  SET_SOH_DYNAMIC_HIERARCHIES_UPDATED,
  SET_SOH_DYNAMIC_PREV_VALUE,
  SET_BUSINESS_UNIT,
  SET_PAGE_REFRESH,
  SET_SOH_DYNAMIC_EXISTING_EDIT_VALUE,
} from "../../actionTypes/commonTypes";
import Authorization from "../../../utility/authorization";
import { downloadxlsxFile, downloadCsvFileNew, getSohDetails } from "../../../utility/helper";
import { FULFILLED, PENDING, REJECTED } from "../../../utility/constant";

export const getGeographicLevel1Options = () => {
  let userDetails = Authorization.getAuthUser();
  return async (dispatch: Dispatch<AnyAction>) => {
    dispatch({ type: LOADING_REQUEST, status: true });
    const { getHierarchyLevels } = apiURL;
    let data = {
      countryCode: userDetails?.countrycode,
    };
    const sohIds = getSohDetails();
    let sohcodes = Authorization.isSOHUser() && { ...sohIds };
    data = { ...data, ...sohcodes };
    invokeGetAuthService(getHierarchyLevels, data)
      .then(async (response: any) => {
        await dispatch(Success(response));
      })
      .catch((error: any) => {
        dispatch({ type: LOADING_REQUEST, status: false });
        dispatch(Fail(error.message));
      });

    function Success(levels: any) {
      return { type: GET_GEOLOCATION_LEVEL1_OPTIONS_SUCCESS, levels };
    }
    function Fail(errorMsg: any) {
      return { type: GET_GEOLOCATION_LEVEL1_OPTIONS_ERROR, errorMsg };
    }
  };
};
export const getGeoLocationFields = () => {
  const getTemplateInfo = Authorization.getTemplateCountry();
  return { type: GET_GEOLOCATION_FIELDS_SUCCESS, getTemplateInfo };
};
export const setGeolevel1Options = (levels: any) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    dispatch({ type: SET_GEOLOCATION_LEVEL1_OPTIONS, levels });
  };
};

export const downloadFile = (data: {}, type: string, pageType: string) => {
  return (dispatch: any) => {
    dispatch({ type: LOADING_REQUEST, status: true });
    let url: string = "";
    if (pageType === "consolidatedScans") {
      url = apiURL.consolidatedScans.downloadScans;
    } else if (pageType === "inventory") {
      url = apiURL.inventory.downloadInventory;
    } else if (pageType === "template") {
      url = apiURL.inventory.downloadTemplate;
    } else if (pageType === "productInventory") {
      url = apiURL.productInventory.downloadProductInventory;
    }
    invokeGetAuthService(url, data)
      .then((response) => {
        if (pageType === "template") {
          downloadCsvFileNew(response, type);
        } else {
          downloadxlsxFile(response, type);
        }
        dispatch({ type: LOADING_REQUEST, status: false });
      })
      .catch((error: any) => {
        dispatch({ type: LOADING_REQUEST, status: false });
        dispatch(Fail(error.message));
      });
    function Fail(errorMsg: any) {
      return { type: GET_GEOLOCATION_FIELDS_ERROR, errorMsg };
    }
  };
};

/**
 * Get Lookup details async action creators
 * Hanlde the Pending, Fullfilled (Success), Rejected action
 *
 * @return object
 */
export function getLookup(data?: any) {
  const { getScanType } = apiURL;
  return {
    types: [`${GET_LOOKUP}_${PENDING}`, `${GET_LOOKUP}_${FULFILLED}`, `${GET_LOOKUP}_${REJECTED}`],
    method: "GET",
    url: getScanType,
    queryParams: data,
  };
}

/**
 * Get Logged current user country  details async action creators
 * Hanlde the Pending, Fullfilled (Success), Rejected action
 *
 * @return object
 */
export function getLoggedUserCountryInfo() {
  let userDetails = Authorization.getAuthUser();
  const { getTemplateData } = apiURL;
  let countrycode = {
    countryCode: userDetails?.countrycode || "",
  };
  return {
    types: [
      `${GET_CURRENT_COUNTRY}_${PENDING}`,
      `${GET_CURRENT_COUNTRY}_${FULFILLED}`,
      `${GET_CURRENT_COUNTRY}_${REJECTED}`,
    ],
    method: "GET",
    url: getTemplateData,
    queryParams: countrycode,
  };
}

/**
 * Get All the partner type options for the respective country
 *
 * @return object
 */
export const getPartnerTypeOptions = () => {
  const countryInfo: any = Authorization.getTemplateCountry();
  const getTemplateInfo = typeof countryInfo === "string" ? JSON.parse(countryInfo) : countryInfo;
  let partnerType = getTemplateInfo?.scanpointallocationdefdatanew;
  let roleHierarchy = getTemplateInfo?.rolehierarchy;
  return { type: GET_ALL_PARTNER_TYPES, partnerType, roleHierarchy };
};

/** To get soh Level details */

export const getSOHHierarchyDetails = (data: any) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    dispatch({ type: LOADING_REQUEST, status: true });
    const { getSohDetails } = apiURL.soh;
    invokeGetAuthService(getSohDetails, data)
      .then(async (response: any) => {
        await dispatch(Success(response?.data));
      })
      .catch((error: any) => {
        dispatch({ type: LOADING_REQUEST, status: false });
        dispatch(Fail(error.message));
      });

    function Success(payload: any) {
      return { type: GET_SOH_DETAILS_SUCCESS, payload };
    }
    function Fail(errorMsg: any) {
      return { type: GET_SOH_DETAILS_ERROR, errorMsg };
    }
  };
};

//To set selected soh cihld value object
export const setSelectedMappedSohChildLevels = (data: any) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    dispatch({ type: SET_SELECTED_MAPPED_SOH_CHILD_LEVELS, data });
  };
};

//To set all selected soh values
export const setAllHierLevelSelectedValues = (data: any) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    dispatch({ type: SET_ALL_SOHLEVEL_SELECTED_VALUES, data });
  };
};

// To set setSohDynamicFields
export const setSohDynamicFields = (data: any) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    dispatch({ type: SET_SOH_DYNAMIC_HIERARCHIES, data });
  };
};

// To set updatedSohDynamicFields
export const setSohDynamicFieldsUpdated = (data: any) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    dispatch({ type: SET_SOH_DYNAMIC_HIERARCHIES_UPDATED, data });
  };
};

// To set updatedSohDynamicFields
export const setSohDynamicFieldsPrev = (data: any) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    dispatch({ type: SET_SOH_DYNAMIC_PREV_VALUE, data });
  };
};

// To set business unit
export const setBusinessUnit = (data: any) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    dispatch({ type: SET_BUSINESS_UNIT, data });
  };
};

// To Indicate Page Refresh
export const setPageRefresh = (data: any) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    dispatch({ type: SET_PAGE_REFRESH, data });
  };
};
// To set starting value
export const setExistingSohValuesInEdit = (data: any) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    dispatch({ type: SET_SOH_DYNAMIC_EXISTING_EDIT_VALUE, data });
  };
};
