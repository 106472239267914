import axios from "axios";
import { configApp } from "../utils/config";
import { setLocalStorageData } from "../../base/localStore";
import { checkSessionTimeOut } from "../../../utility/helper";
import Authorization from "../../../utility/authorization";
import moment from "moment";

// Request headers
const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
  "Access-Control-Allow-Origin": "*",
  "client-tz": Intl.DateTimeFormat().resolvedOptions().timeZone,
  "client-tz-offset": new Date().getTimezoneOffset(),
};

// reload page when session finished
const isReload = true;

const handleSessionLogout = () => {
  setTimeout(() => {
    Authorization.logOut(isReload);
  }, 5000);
};

/**
 * Set Authorization token in header
 * @returns Request Headers
 */
const setAuthHeader = () => {
  const authToken = Authorization.getAccessToken();
  let authHeader = {
    ...headers,
    Authorization: `Bearer ${authToken}`,
  };
  return authHeader;
};
//Post method with auth (without session timeout)
export function invokePostServiceLogin(path, reqObj, params) {
  return new Promise(function (resolve, reject) {
    const apiEndPoint = configApp.env;
    const config = {
      method: "POST",
      data: reqObj,
      params: params,
      headers: setAuthHeader(),
    };
    axios
      .create({
        baseURL: apiEndPoint + path,
      })(config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        if (err.response) {
          getAuthToken(err);
          reject(err.response.data);
        } else {
          reject(err);
        }
      });
  });
}

//Get method with auth (Without Session Timeout)
export function invokeGetServiceWithoutSession(path, formData) {
  return new Promise(function (resolve, reject) {
    const URL = configApp.env;
    const config = {
      method: "GET",
      headers: setAuthHeader(),
      params: {
        ...formData,
      },
    };
    axios
      .create({
        baseURL: URL + path,
      })(config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        if (err.response) {
          getAuthToken(err);
          reject(err.response.data);
        } else {
          reject(err);
        }
      });
  });
}

//Post method with auth (With session timeout)
export function invokePostAuthService(path, reqObj, params) {
  return new Promise(function (resolve, reject) {
    if (checkSessionTimeOut()) {
      const apiEndPoint = configApp.env;
      const config = {
        method: "POST",
        data: reqObj,
        params: params,
        headers: setAuthHeader(),
      };
      axios
        .create({
          baseURL: apiEndPoint + path,
        })(config)
        .then((response) => {
          setLocalStorageData("sessionTime", moment().unix());
          resolve(response.data);
        })
        .catch((err) => {
          if (err.response) {
            getAuthToken(err);
            reject(err.response.data);
          } else {
            reject(err);
          }
        });
    } else {
      handleSessionLogout();
    }
  });
}

//post with different headers
export function invokePostFileAuthService(path, reqObj, params) {
  return new Promise(function (resolve, reject) {
    if (checkSessionTimeOut()) {
      const apiEndPoint = configApp.env;
      const config = {
        method: "POST",
        headers: {
          ...setAuthHeader(),
          "Content-type": "multipart/form-data",
        },
        data: reqObj,
        params: params,
      };
      axios
        .create({
          baseURL: apiEndPoint + path,
        })(config)
        .then((response) => {
          setLocalStorageData("sessionTime", moment().unix());
          resolve(response.data);
        })
        .catch((err) => {
          if (err.response) {
            getAuthToken(err);
            reject(err.response.data);
          } else {
            reject(err);
          }
        });
    } else {
      handleSessionLogout();
    }
  });
}

//Get method with auth (With Session Timeout)
export function invokeGetService(path) {
  return new Promise(function (resolve, reject) {
    if (checkSessionTimeOut()) {
      const URL = configApp.env;
      const config = {
        method: "GET",
        headers: setAuthHeader(),
      };
      axios
        .create({
          baseURL: URL + path,
        })(config)
        .then((response) => {
          setLocalStorageData("sessionTime", moment().unix());
          resolve(response.data);
        })
        .catch((err) => {
          if (err.response) {
            getAuthToken(err);
            reject(err.response.data);
          } else {
            reject(err);
          }
        });
    } else {
      handleSessionLogout();
    }
  });
}

//Get method with auth
export function invokeGetAuthService(path, formData) {
  return new Promise(function (resolve, reject) {
    if (checkSessionTimeOut()) {
      const URL = configApp.env;
      const config = {
        method: "GET",
        params: {
          ...formData,
        },
        headers: setAuthHeader(),
      };

      axios
        .create({
          baseURL: URL + path,
        })(config)
        .then((response) => {
          setLocalStorageData("sessionTime", moment().unix());
          resolve(response.data);
        })
        .catch((err) => {
          if (err.response) {
            getAuthToken(err);
            reject(err.response.data);
          } else {
            reject(err);
          }
        });
    } else {
      handleSessionLogout();
    }
  });
}

//Post method with auth (With Session Timeout)
export function invokePostService(path, reqObj, params) {
  return new Promise(function (resolve, reject) {
    if (checkSessionTimeOut()) {
      const apiEndPoint = configApp.env;
      const config = {
        method: "POST",
        data: reqObj,
        params: params,
        headers: setAuthHeader(),
      };
      axios
        .create({
          baseURL: apiEndPoint + path,
        })(config)
        .then((response) => {
          setLocalStorageData("sessionTime", moment().unix());
          resolve(response.data);
        })
        .catch((err) => {
          if (err.response) {
            getAuthToken(err);
            reject(err.response.data);
          } else {
            reject(err);
          }
        });
    } else {
      handleSessionLogout();
    }
  });
}

//Put method
export function invokePutService(path, reqObj) {
  return new Promise(function (resolve, reject) {
    if (checkSessionTimeOut()) {
      const apiEndPoint = configApp.env;
      const config = {
        method: "PUT",
        data: reqObj,
        headers: setAuthHeader(),
      };
      axios
        .create({
          baseURL: apiEndPoint + path,
        })(config)
        .then((response) => {
          setLocalStorageData("sessionTime", moment().unix());
          resolve(response.data);
        })
        .catch((err) => {
          if (err.response) {
            getAuthToken(err);
            reject(err.response.data);
          } else {
            reject(err);
          }
        });
    } else {
      handleSessionLogout();
    }
  });
}

//Akana Api integration
export function invokeAkanaDemoService() {
  return new Promise(function (resolve, reject) {
    // const URL = configApp.env;
    const config = {
      method: "GET",
      headers: setAuthHeader(),
    };
    axios
      .create({
        //baseURL: "https://bayer-reward-plus.herokuapp.com/api/oauth/token", //URL + 'oauth/token',
        baseURL: process.env.REACT_APP_OAUTH,
      })(config)
      .then((response) => {
        setLocalStorageData("sessionTime", moment().unix());
        setLocalStorageData("accessToken", JSON.stringify(response.data.body.access_token));
        resolve(response);
      })
      .catch((err) => {
        if (err.response) {
          reject(err.response);
        } else {
          reject(err);
        }
      });
  });
}

function getAuthToken(err) {
  if (err?.response?.status === 401) {
    invokeAkanaDemoService();
    setTimeout(() => {
      window.location.reload();
    }, 1500);
  }
}
