export const GET_GEOLOCATION_LEVEL1_OPTIONS_SUCCESS = "GET_GEOLOCATION_LEVEL1_OPTIONS_SUCCESS";
export const GET_GEOLOCATION_LEVEL1_OPTIONS_ERROR = "GET_GEOLOCATION_LEVEL1_OPTIONS_ERROR";
export const LOADING_REQUEST = "LOADING_REQUEST";
export const GET_GEOLOCATION_FIELDS_SUCCESS = "GET_GEOLOCATION_FIELDS_SUCCESS";
export const GET_GEOLOCATION_FIELDS_ERROR = "GET_GEOLOCATION_FIELDS_ERROR";
export const SET_GEOLOCATION_LEVEL1_OPTIONS = "SET_GEOLOCATION_LEVEL1_OPTIONS";
export const SET_OVERALL_SCANS = "SET_OVERALL_SCANS";
export const GET_LOOKUP = "GET_LOOKUP";
export const GET_CURRENT_COUNTRY = "GET_CURRENT_COUNTRY";
export const GET_ALL_PARTNER_TYPES = "GET_ALL_PARTNER_TYPES";
export const GET_SOH_DETAILS_SUCCESS = "GET_SOH_DETAILS_SUCCESS";
export const GET_SOH_DETAILS_ERROR = "GET_SOH_DETAILS_ERROR";
export const SET_SELECTED_MAPPED_SOH_CHILD_LEVELS = "SET_SELECTED_MAPPED_SOH_CHILD_LEVELS";
export const SET_ALL_SOHLEVEL_SELECTED_VALUES = "SET_ALL_SOHLEVEL_SELECTED_VALUES";
export const SET_SOH_DYNAMIC_HIERARCHIES = "SET_SOH_DYNAMIC_HIERARCHIES";
export const SET_SOH_DYNAMIC_HIERARCHIES_UPDATED = "SET_SOH_DYNAMIC_HIERARCHIES_UPDATED";
export const SET_SOH_DYNAMIC_PREV_VALUE = "SET_SOH_DYNAMIC_PREV_VALUE";
export const SET_BUSINESS_UNIT = "SET_BUSINESS_UNIT";
export const SET_PAGE_REFRESH = "SET_PAGE_REFRESH";
export const SET_SOH_DYNAMIC_EXISTING_EDIT_VALUE = "SET_SOH_DYNAMIC_EXISTING_EDIT_VALUE";
