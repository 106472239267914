import React from "react";
import { withRouter } from "react-router-dom";
import Aux from "../../hoc/Aux_";
import TopBar from "./TopBar";
import SideBar from "./SideBar";
import Authorization from "../../utility/authorization";

function Layout(props: any, currentLocale?: any, handleChange?: any) {
  return Authorization.isLoggedIn() ? (
    <Aux>
      <div id="wrapper">
        <TopBar currentLocale={currentLocale} handleChange={handleChange} {...props} />
        <SideBar {...props} />
        <div className="content-page">
          <div className="content">{props.children}</div>
        </div>
      </div>
    </Aux>
  ) : (
    props.children
  );
}
export default withRouter(Layout);
