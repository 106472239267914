// import axios from "axios";
import moment from "moment";
import Authorization from "./authorization";
import { setLocalStorageData } from "./base/localStore";
import { invokeAkanaDemoService } from "./base/service";
import { checkSessionTimeOut, isObject } from "./helper";

class RequestFactory {
  /**
   * static class property to hold various service avaliable
   *
   * @var array
   */
  static BAYER = "REACT_APP_API";
  static LOCALHOST = "localhost";
  /**
   * static class property to hold various request body types avaliable
   *
   * @var string
   */
  static REQUEST_BODY_TYPE_FORM_DATA = "FormData";
  static REQUEST_BODY_TYPE_RAW = "Raw";
  static REQUEST_BODY_TYPE_JSON = "application/json";
  static REQUEST_BODY_TYPE_IMAGE = "Image";
  static REQUEST_BODY_TYPE_FILE = "File";
  /**
   * static class property to hold the request body type available
   *
   * @var array
   */
  static requestBodyTypes = ["FormData", "Raw", "application/json", "Image", "File"];
  /**
   * static class property to hold the available for the application
   *
   * @var object
   */
  static services = {
    BASE_API: "REACT_APP_API",
  };
  /**
   * static class property to hold the unallowed request params
   *
   * @var array
   */
  static unallowedRequestParamKeys = ["inputErrors"];
  service: any;
  headers: any;
  requestBodyType: any;
  _requestBodyType: string | undefined;

  constructor() {
    this.resetFactory();
  }
  /**
   * set this service current request made with
   *
   * @param service
   * @return this
   */
  withService(service: any) {
    this.service = service;

    return this;
  }
  /**
   * check request is failed due to network connection
   *
   * @param responseMessage
   * @return boolean
   */
  isFetchFailure(responseMessage: any) {
    return (
      responseMessage === "Failed to fetch" ||
      responseMessage === "NetworkError when attempting to fetch resource." ||
      responseMessage === "Network request failed"
    );
  }
  /**
   * set headers for current request
   *
   * @param key
   * @param value
   * @return this
   */
  setHeaders(key: any, value: any) {
    this.headers[key] = value;

    return this;
  }
  /**
   * remove headers from existing for current request
   *
   * @param key
   * @return this
   */
  removeHeaders(key: any) {
    if (this.headers.has(key)) {
      this.headers.delete(key);
    }

    return this;
  }
  /**
   * set this service current request made with
   *
   * @param service
   * @return this
   */
  withRequestBodyType(requestBodyType: any) {
    this.requestBodyType = requestBodyType;

    return this;
  }
  /**
   * reset this property default for request
   *
   * @return void
   */
  resetFactory() {
    this.headers = new Headers({
      Accept: "*/*",
      // Pragma: "no-cache",
      // "Cache-Control": "no-cache, no-store",
      "Content-Type": "application/x-www-form-urlencoded; charset=utf-8",
    });
    this.setHeaders("client-tz", Intl.DateTimeFormat().resolvedOptions().timeZone);
    this.setHeaders("client-tz-offset", new Date().getTimezoneOffset());
   
     this.service = process.env.REACT_APP_BRP_API_HOST;
    
    
    this.requestBodyType = RequestFactory.REQUEST_BODY_TYPE_JSON;
    // this.requestBodyType = RequestFactory.REQUEST_BODY_TYPE_FORM_DATA;
  }
  /**
   * check key is allowed for request param
   *
   * @param key
   * @return boolean
   */
  isAllowedRequestParamKey(key: any) {
    return RequestFactory.unallowedRequestParamKeys.indexOf(key) === -1;
  }
  /**
   * get available headers
   *
   * @return headers
   */
  getHeaders() {
    let accessToken = Authorization.getAccessToken();
    if (this.requestBodyType !== "File") {
      this.headers.set("Content-Type", "application/json");
    } else {
      this.removeHeaders("Content-Type");
    }
    if (accessToken) {
      this.headers.set("Authorization",`Bearer ${accessToken}`);
      //this.setHeaders("Authorization", `Bearer ${accessToken}`);
    }

    return this.headers;
  }
  /**
   * get base api url
   *
   * @return string
   */
  getBaseApiUrl() {
    return process.env.REACT_APP_BRP_API_HOST;
    // return env.getEnv(RequestFactory.services["BASE_API"]);
  }
  /**
   * convert the object to raw data(string) by
   * Serialize the object with url encode
   * used when form data is not prepared for request body
   * require jquery
   * to work without jquery modify logic defined
   *
   * @param object
   * @return object
   */
  convertObjectToRawData(obj: any) {
    return JSON.stringify(obj);
  }
  /**
   * filter the request paran
   * remove the unallowed request params
   *
   * @param object
   * @return object
   */
  filterRequestParam(data: any) {
    if (typeof data === "object" && Object.keys(data).length > 0) {
      RequestFactory.unallowedRequestParamKeys.forEach((key) => {
        if (data.hasOwnProperty(key)) {
          delete data[key];
        }
      });
    }

    return data;
  }
  /**
   * get base api url
   *
   * @param object
   * @return object
   */
  getRequestBody(data: any) {
    var body: any;
    data = this.filterRequestParam(data);

    switch (this.requestBodyType) {
      case RequestFactory.REQUEST_BODY_TYPE_RAW:
        body = this.convertObjectToRawData(data);
        break;
      case RequestFactory.REQUEST_BODY_TYPE_JSON:
        body = JSON.stringify(data);
        break;
      case RequestFactory.REQUEST_BODY_TYPE_IMAGE:
        body = data;
        break;
      case RequestFactory.REQUEST_BODY_TYPE_FILE:
        body = data;
        break;
      default:
        body = new FormData();
        Object.keys(data).forEach((key) => {
          body.append(key, data[key]);
        });
        break;
    }
    return body;
  }
  /**
   * get request
   * @param url
   * @param successCallback
   * @param errorCallback
   * @param queryParams
   * @return void
   */
  get(url: any, successCallback: any, errorCallback: any, queryParams: any) {
    let headers = this.getHeaders();

    return this.request(
      this.getUrl(url, queryParams),
      {
        method: "GET",
        headers: headers,
        mode: "cors",
      },
      successCallback,
      errorCallback
    );
  }
  /**
   * post request
   * @param url
   * @param data
   * @param successCallback
   * @param errorCallback
   * @param queryParams
   * @return void
   */
  post(url: any, data: any, successCallback: any, errorCallback: any, queryParams: any) {
    this.request(
      this.getUrl(url, queryParams),
      {
        method: "POST",
        headers: this.getHeaders(),
        data: this.getRequestBody(data),
      },
      successCallback,
      errorCallback
    );
  }
  /**
   * put request
   * @param url
   * @param data
   * @param successCallback
   * @param errorCallback
   * @param queryParams
   * @return void
   */
  put(url: any, data: any, successCallback: any, errorCallback: any, queryParams: any) {
    this.request(
      this.getUrl(url, queryParams),
      {
        method: "PUT",
        headers: this.getHeaders(),
        data: this.getRequestBody(data),
      },
      successCallback,
      errorCallback
    );
  }
  /**
   * delete request
   * @param url
   * @param data
   * @param successCallback
   * @param errorCallback
   * @param queryParams
   * @return void
   */
  delete(url: any, data: any, successCallback: any, errorCallback: any, queryParams: any) {
    this.request(
      this.getUrl(url, queryParams),
      {
        method: "DELETE",
        headers: this.getHeaders(),
        data: this.getRequestBody(data),
      },
      successCallback,
      errorCallback
    );
  }
  /**
   * request
   *
   * @param url
   * @param config
   * @param successCallback
   * @param errorCallback
   * @return void
   */
  request(url: any, config: any, successCallback: any, errorCallback: any) {
    this.resetFactory();
    //clear the cache in IE 11 browser
    // axios.defaults.headers["Pragma"] = "no-cache";
  return fetch(url, config)
  .then(this.responseParser())
    .then(this.successCallback(successCallback, errorCallback))
    .catch(this.errorCallback(errorCallback));
  }
  /**
   * response parser
   *
   * @return string
   */
  responseParser() {
    return (response: any) => {
      if (response.status >= 400 && response.status < 600) {
        return response.json().then((err: any) => {
          // eslint-disable-next-line
          throw err ={...err,status:response.status};
        });
      }
      // check for header and send the content

      if (response.headers.get("content-type").indexOf("application/json") !== -1) {
        // checking response header
        return response.json();
      } else {
        return response.blob();
      }
    };
  }
  /**
   * callback the sucess method
   * @param callback
   * @param errorCallback
   * @return string
   */
  successCallback(callback: any, errorCallback: any) {
    return (json: any) => {
      if (isObject(json) && json.hasOwnProperty("is_token_invalid")) {
        errorCallback(json);
      } else if (typeof callback === "function") {
        setLocalStorageData("sessionTime", moment().unix());
        callback(json);
      }
    };
  }
  /**
   * callback the error method
   *
   * @param callback
   * @return string
   */
  errorCallback(callback: any) {
    // check whether received status is unauthorized
    return (response: any) => {
      if(response.status===401){
        invokeAkanaDemoService();
        setTimeout(() => {
          window.location.reload();
        }, 1500);
        
      }
      if (response.response) {
        if (response.response.status === 401 || response.response.status === 403) {
          if (
            response.response.data &&
            response.response.data.message &&
            response.response.data.message.JsonWebTokenError &&
            response.response.data.message.JsonWebTokenError === "invalid token"
          ) {
            setTimeout(() => {
              Authorization.logOut();
              window.location.reload();
            }, 1000);
          }

          if (
            response.response.data &&
            response.response.data.message &&
            response.response.data.message.TokenExpiredError &&
            response.response.data.message.TokenExpiredError === "jwt expired"
          ) {
            setTimeout(() => {
              Authorization.logOut();
              window.location.reload();
            }, 1000);
          }
        }
      }
      if (typeof callback === "function") {
        callback(response.response && response.response.data ? response.response.data : response.response);
      }
    };
  }
  /**
   * build the query
   *
   * @param queryParams
   * @return string
   */
  buildQueryParams(queryParams: any) {
    var params: any = false;
    var queryLength = Object.keys(queryParams).length;
    var i = 1;

    for (var iter in queryParams) {
      if (typeof queryParams[iter] !== "undefined") {
        if (!params) {
          params = "?";
        }
        if (typeof queryParams[iter] === "object") {
          for (var queryParamIter in queryParams[iter]) {
            params += iter + "[" + queryParamIter + "]=" + queryParams[iter][queryParamIter];
            params += "&";
          }
        } else {
          params += iter + "=" + queryParams[iter];
        }

        if (i < queryLength) {
          params += "&";
        }

        i++;
      }
    }

    return params;
  }
  /**
   * get the url
   *
   * @param path
   * @param queryParams
   * @return string
   */
  getUrl(path: any, queryParams: any) {
    var url = this.getBaseApiUrl() + path;
    return queryParams ? url + this.buildQueryParams(queryParams) : url;
  }

  /**
   * call request
   * @param url
   * @param data
   * @param successCallback
   * @param errorCallback
   * @param queryParams
   * @return void
   */

  call(type: any, url: any, data: any, successCallback: any, errorCallback: any, queryParams: any) {
    if (checkSessionTimeOut()) {
      if (type === "GET") {
        let headers = this.getHeaders();
        this.request(
          this.getUrl(url, queryParams),
          {
            method: "GET",
            headers: headers,
            mode: "cors",
          },
          successCallback,
          errorCallback
        );
      } else {
        if (this.requestBodyType !== RequestFactory.REQUEST_BODY_TYPE_IMAGE) {
          this.setHeaders("Content-Type", "application/json;charset=UTF-8");
        } else {
          this.removeHeaders("Content-Type");
        }
        this.request(
          this.getUrl(url, queryParams),
          {
            method: type,
            headers: this.getHeaders(),
            data: this.getRequestBody(data),
          },
          successCallback,
          errorCallback
        );
      }
    } else {
      setTimeout(() => {
        Authorization.logOut();
        window.location.reload();
      }, 1000);
    }
  }
}

export default new RequestFactory();
