import { Component } from "react";
import { Link } from "react-router-dom";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import $ from "jquery";
import Aux from "../../hoc/Aux_";
import homeIcon from "../../assets/icons/home_icon.svg";
import addUserIcon from "../../assets/icons/add_user_icon.svg";
import userListIcon from "../../assets/icons/list_user_icon.svg";
import scanLogsIcon from "../../assets/icons/scan_logs_icon.svg";
import consolidatescans from "../../assets/icons/consolidated_scan.svg";
import orderHistoryIcon from "../../assets/icons/order_history.svg";
import inventory from "../../assets/icons/inventory.svg";
import logoutIcon from "../../assets/icons/logout_icon.svg";
import lLogo from "../../assets/icons/large_logo_holder.svg";
import NoImage from "../../assets/images/no_image.svg";
import country from "../../assets/images/country.svg";
import box from "../../assets/images/box.svg";
import redeem from "../../assets/icons/redeem.svg";
import returnIcon from "../../assets/images/return-package.svg";
import { getLocalStorageData } from "../../utility/base/localStore";
import Authorization from "../../utility/authorization";
import { AppContext } from "../context";
import LabelImg from "../../assets/images/Scanner.svg";
import DehazeIcon from "@material-ui/icons/Dehaze";
import _ from "lodash";
import { connect } from "react-redux";
import { getFeatures } from "../../utility/helper";
import { setBusinessUnit, setSohDynamicFieldsPrev } from "../../redux/actions/common/common";
import { apiURL } from "../../utility/base/utils/config";
import { invokeGetAuthService } from "../../utility/base/service";

type Props = {
  history?: any;
  country?: any;
  dispatch?: any;
} & WrappedComponentProps;
type States = {
  activeTab: any;
  isOrderHistory: boolean;
  //isReturnsDataAvailable: any;
  isExpanded: boolean;
  isInvoiceDetails: boolean;
};
class Sidebar extends Component<Props, States> {
  static contextType = AppContext;
  availableFeatures: any;
  templateData: any;
  constructor(props: any) {
    super(props);
    this.state = {
      activeTab: "dashboard",
      isOrderHistory: false,
      //isReturnsDataAvailable: "",
      isExpanded: false,
      isInvoiceDetails: false,
    };
    $("body").addClass("enlarged");
  }

  componentDidMount() {
    let obj: any = getLocalStorageData("userData");
    let userData = JSON.parse(obj);
    this.getLocationHierachyOrder(userData?.countrycode);
    $(".button-menu-mobile").on("click", (event: any) => {
      event.preventDefault();
      $("body").toggleClass("enlarged");
      this.setState((prevState) => {
        return { isExpanded: !prevState.isExpanded };
      });
    });

    // const isReturnsDataAvailable = getLocalStorageData("isReturnsDataAvailable");
    // this.setState({
    //   isReturnsDataAvailable: isReturnsDataAvailable,
    // });
  }
  /**
   * To get the dev config list
   */
  getLocationHierachyOrder = (countrycode: any) => {
    const { getTemplateData } = apiURL;
    let data = {
      countryCode: countrycode,
    };
    invokeGetAuthService(getTemplateData, data).then((response: any) => {
      if (response?.body?.length > 0) {
        this.templateData = response.body[0];
        if (!_.isEmpty(this.templateData)) {
          this.isInvokeAllow(this.templateData);
        }
      }
    });
  };

  setActiveTab = (tab: any) => {
    this.setState({ activeTab: tab });
  };
  /**
   * To logout page and check the unsaved change value for Prompt
   */
  logout = () => {
    const { promptMode } = this.context;
    this.props.dispatch(setSohDynamicFieldsPrev([]));
    this.props.dispatch(setBusinessUnit(""));
    if (!promptMode) Authorization.logOut();
  };

  isInvokeAllow = (data: any) => {
    const resp = data?.additionaldefdata[0]?.iswinsafe;
    let scan = data?.scanpointallocationdefdatanew;
    let scantypes = scan?.map((ele: any) => ele?.allowedscantypes);
    if (scantypes?.includes("SCAN_OUT_W2D") || scantypes?.includes("SCAN_OUT_W2R")) {
      this.setState({ isInvoiceDetails: resp });
    }
  };

  render() {
    const { activeTab, isExpanded } = this.state;
    const { intl } = this.props;
    const isOrderHistory = getLocalStorageData("isOrderHistory");

    this.availableFeatures = getFeatures();
    return (
      <Aux>
        <div className="left side-menu">
          <img className="sideMenuLine" src={lLogo} data-testid="left-logo" alt={NoImage} />
          <div className="sideMenuNav">
            <div id="sidebar-menu" className="">
              <ul className="metismenu" id="side-menu">
                <li className="d-flex" title={`${!isExpanded ? intl?.messages["sideBar.dashboard"] : ""}`}>
                  <span
                    className={window.location.pathname.indexOf("dashboard") > -1 ? "waves-effect active" : "waves-effect"}
                  ></span>
                  <Link to="/dashboard" onClick={() => this.setActiveTab("dashboard")}>
                    <img src={homeIcon} alt={"dashboard"} width="16" data-testid="dashboard-icon" />{" "}
                    <span>
                      <FormattedMessage id="sideBar.dashboard" />{" "}
                    </span>
                  </Link>
                </li>

                {(Authorization.isSOHUser() || Authorization.isAdmin()) && (
                  <>
                    {this.availableFeatures?.length > 0 && (
                      <li className="menu-title">
                        <FormattedMessage id="sideBar.management" />
                      </li>
                    )}
                    {this.availableFeatures?.includes("CREATE_NEW_USER") ||
                    this.availableFeatures?.includes("CREATE_NEW_FO") ||
                    this.availableFeatures?.includes("CREATE_NEW_DISTRIBUTOR") ? (
                      <li className="d-flex" title={`${!isExpanded ? intl?.messages["sideBar.createNewUser"] : ""}`}>
                        <span
                          className={
                            window.location.pathname.indexOf("createUser") > -1 ? "waves-effect active" : "waves-effect"
                          }
                        ></span>
                        <Link
                          to="/createUser"
                          onClick={(e: any) =>
                            window.location.pathname.indexOf("createUser") > -1
                              ? e.preventDefault()
                              : this.setActiveTab("createUser")
                          }
                        >
                          <img src={addUserIcon} alt={NoImage} width="16" data-testid="createuser-icon" />{" "}
                          <span>
                            <FormattedMessage id="sideBar.createNewUser" />
                          </span>
                        </Link>
                      </li>
                    ) : null}

                    {this.availableFeatures?.includes("USER_LIST_SCREEN") ? (
                      <li className="d-flex" title={`${!isExpanded ? intl?.messages["sideBar.userList"] : ""}`}>
                        <span
                          className={
                            window.location.pathname.indexOf("userList") > -1 ? "waves-effect active" : "waves-effect"
                          }
                        ></span>
                        <Link to="/userList" onClick={() => this.setActiveTab("userList")}>
                          <img src={userListIcon} alt={NoImage} width="16" data-testid="listuser-icon" />{" "}
                          <span>
                            <FormattedMessage id="sideBar.userList" />
                          </span>
                        </Link>
                      </li>
                    ) : null}

                    {!isOrderHistory && this.availableFeatures?.includes("ORDER_HISTORY_WEB") ? (
                      <li className="d-flex" title={`${!isExpanded ? intl?.messages["sideBar.orderHistory"] : ""}`}>
                        <span
                          className={window.location.pathname.indexOf("order") > -1 ? "waves-effect active" : "waves-effect"}
                        ></span>
                        <Link to="/order" onClick={() => this.setActiveTab("order")}>
                          <img src={orderHistoryIcon} alt={NoImage} width="16" data-testid="order-icon" />{" "}
                          <span>
                            <FormattedMessage id="sideBar.orderHistory" />
                          </span>
                        </Link>
                      </li>
                    ) : null}
                  </>
                )}

                {Authorization.isDEVAdmin() && (
                  <>
                    <li className="menu-title">
                      <FormattedMessage id="sideBar.management" />
                    </li>
                    <li className="d-flex" title={`${!isExpanded ? intl?.messages["sideBar.devConfig"] : ""}`}>
                      <span
                        className={
                          window.location.pathname.indexOf("devconfig") > -1 ? "waves-effect active" : "waves-effect"
                        }
                      ></span>

                      <Link
                        to="/devconfig"
                        className={activeTab === "devconfig" ? "waves-effect active" : "waves-effect"}
                        onClick={() => this.setActiveTab("devconfig")}
                      >
                        <img src={country} alt="User" width="16" />{" "}
                        <span>
                          <FormattedMessage id="sideBar.devConfig" />
                        </span>
                      </Link>
                    </li>
                  </>
                )}

                {Authorization.isRSMAdmin() && this.availableFeatures?.length > 0 && (
                  <li className="menu-title">
                    <FormattedMessage id="sideBar.logs" />
                  </li>
                )}
                {this.availableFeatures?.includes("SCAN_LOGS") ? (
                  <li className="d-flex" title={`${!isExpanded ? intl?.messages["sideBar.scanLogs"] : ""}`}>
                    <span
                      className={window.location.pathname.indexOf("scanlogs") > -1 ? "waves-effect active" : "waves-effect"}
                    ></span>
                    <Link to="/scanlogs" onClick={() => this.setActiveTab("scanlogs")}>
                      <img src={scanLogsIcon} alt="Sacn" width="16" />{" "}
                      <span>
                        <FormattedMessage id="sideBar.scanLogs" />
                      </span>
                    </Link>
                  </li>
                ) : null}

                {this.availableFeatures?.includes("CONSOLIDATED_SCANS") ? (
                  <li className="d-flex" title={`${!isExpanded ? intl?.messages["sideBar.consolidatedScans"] : ""}`}>
                    <span
                      className={
                        window.location.pathname.indexOf("consolidatedScans") > -1 ? "waves-effect active" : "waves-effect"
                      }
                    ></span>
                    <Link to="/consolidatedScans" onClick={() => this.setActiveTab("consolidatedScans")}>
                      <img src={consolidatescans} alt="Consolidated Scans" width="16" />{" "}
                      <span>
                        <FormattedMessage id="sideBar.consolidatedScans" />
                      </span>
                    </Link>
                  </li>
                ) : null}

                {this.availableFeatures?.includes("CP_INVENTORY_SCREEN") ? (
                  <li className="d-flex" title={`${!isExpanded ? intl?.messages["sideBar.inventory"] : ""}`}>
                    <span
                      className={window.location.pathname.indexOf("inventory") > -1 ? "waves-effect active" : "waves-effect"}
                    ></span>
                    <Link to="/inventory" onClick={() => this.setActiveTab("inventory")}>
                      <img src={inventory} alt="Inventory" width="16" />
                      <span>
                        <FormattedMessage id="sideBar.inventory" />
                      </span>
                    </Link>
                  </li>
                ) : null}
                {this.availableFeatures?.includes("LABEL_ID_MANAGEMENT") ? (
                  <li className="d-flex" title={`${!isExpanded ? intl?.messages["sideBar.label"] : ""}`}>
                    <span
                      className={window.location.pathname.indexOf("label") > -1 ? "waves-effect active" : "waves-effect"}
                    ></span>
                    <Link to="/label" onClick={() => this.setActiveTab("label")}>
                      <img src={LabelImg} alt="Label" width="16" />
                      <span>
                        <FormattedMessage id="sideBar.label" />
                      </span>
                    </Link>
                  </li>
                ) : null}
                {this.availableFeatures?.includes("RETURNS_LOG") ? (
                  <li className="d-flex" title={`${!isExpanded ? intl?.messages["returns.title"] : ""}`}>
                    <span
                      className={window.location.pathname.indexOf("returns") > -1 ? "waves-effect active" : "waves-effect"}
                    ></span>
                    <Link to="/returns" onClick={() => this.setActiveTab("returns")}>
                      <img src={returnIcon} alt="returnIcon" width="16" />{" "}
                      <span>
                        <FormattedMessage id="returns.title" />
                      </span>
                    </Link>
                  </li>
                ) : null}

                {this.availableFeatures?.includes("PRODUCT_MANAGEMENT") ? (
                  <li className="d-flex" title={`${!isExpanded ? intl?.messages["product.title"] : ""}`}>
                    <span
                      className={window.location.pathname.indexOf("product") > -1 ? "waves-effect active" : "waves-effect"}
                    ></span>
                    <Link to="/product" onClick={() => this.setActiveTab("product")}>
                      <img src={box} alt="product" width="16" />{" "}
                      <span>
                        <FormattedMessage id="product.title" />
                      </span>
                    </Link>
                  </li>
                ) : null}

                {/* invoice */}

                {this.state.isInvoiceDetails && this.availableFeatures?.includes("INVOICE_VIEW") ? (
                  <li className="d-flex" title={`${!isExpanded ? intl?.messages["invoice.title"] : ""}`}>
                    <span
                      className={window.location.pathname.indexOf("invoice") > -1 ? "waves-effect active" : "waves-effect"}
                    ></span>
                    <Link to="/invoice" onClick={() => this.setActiveTab("invoice")}>
                      <DehazeIcon style={{ width: "26px", height: "27px" }}></DehazeIcon>
                      {/* <img src={DehazeIcon} alt="invoice" width="16" />{" "} */}
                      <span>
                        <FormattedMessage id="invoice.title" />
                      </span>
                    </Link>
                  </li>
                ) : null}

                {/* Redemption */}
                {this.availableFeatures?.includes("LOYALTY_REDEMPTION_HISTORY") ? (
                  <li className="d-flex" title={`${!isExpanded ? intl?.messages["redemption.title"] : ""}`}>
                    <span
                      className={
                        window.location.pathname.indexOf("redemption") > -1 ? "waves-effect active" : "waves-effect"
                      }
                    ></span>
                    <Link to="/redemption" onClick={() => this.setActiveTab("redemption")}>
                      <img src={redeem} alt="redemption" width="16" />{" "}
                      <span>
                        <FormattedMessage id="redemption.title" />
                      </span>
                    </Link>
                  </li>
                ) : null}
              </ul>
            </div>

            <div className="clearfix"></div>
            {/* </Scrollbars> */}
          </div>
          <div id="sidebar-menu" className="">
            <ul className="metismenu" id="side-menu">
              <li className="d-flex" title={`${!isExpanded ? intl?.messages["sideBar.logout"] : ""}`}>
                <span
                  className={window.location.pathname.indexOf("landing") > -1 ? "waves-effect active" : "waves-effect"}
                ></span>
                <Link to="/landing" onClick={this.logout}>
                  <img src={logoutIcon} alt={"logout"} width="16" data-testid="logout-icon" />{" "}
                  <span>
                    <FormattedMessage id="sideBar.logout" />
                  </span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </Aux>
    );
  }
}

export default connect((state: any) => {
  return {};
})(injectIntl(Sidebar));
