import React, { Suspense, useState } from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import { Switch, Router } from "react-router-dom";
import { Provider } from "react-redux";
// import store from './store/store';
import { store } from "./redux/store/index";
import PrivateRoute from "./routes/privateRoute";
import PublicRoute from "./routes/publicRoute";
import { ROUTE } from "./routes/routes";
import Layout from "./containers/layout";
import Loader from "./utility/widgets/loader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/scss/index.scss";
// To enable isRemember Need to logged out
import Authorization from "./utility/authorization";
import AppProvider from "./containers/context";

import history from "./history";

// Localization imports
import { IntlProvider } from "react-intl";
import flatten from "flat";
import messages_en from "./lang/en.json";
import messages_es from "./lang/es.json";
import messages_fr from "./lang/fr.json";

import { invokeAkanaDemoService } from "./utility/base/service";

// MSAL imports
import { PublicClientApplication, EventType, EventMessage, AuthenticationResult } from "@azure/msal-browser";
import { msalConfig } from "./utility/base/utils/authConfig";
import { MsalProvider } from "@azure/msal-react";

export const msalInstance = new PublicClientApplication(msalConfig);
msalInstance["browserStorage"].clear();

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

// Localization country code
const messages: any = {
  en: messages_en,
  es: messages_es,
  fr: messages_fr,
};

/**
 * Create the routes dynamically
 *
 * @return route component
 */
const setRoutes = (currentLocale: any, handleChange: any) => {
  const routes = ROUTE;
  return routes.map((route, index) =>
    route.private ? (
      <PrivateRoute
        key={index}
        path={route.path}
        meta={route.meta}
        exact={route.exact}
        component={route.component}
        role={route.role}
      />
    ) : (
      <PublicRoute
        key={index}
        path={route.path}
        meta={route.meta}
        exact={route.exact}
        component={route.component}
        currentLocale={currentLocale}
        handleChange={handleChange}
      />
    )
  );
};

/**
 * To handle remember me options(close brower tab or browser)
 * 
 */
function isRemember() {
  if (!sessionStorage.userLoggedIn) {
    if (!Authorization.isRemember()) Authorization.logOut();
  }
}
isRemember();

const App = () => {
  // Function to clear complete cache data
  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  };
  clearCacheData()


  const [currentLocale, setCurrentLocale] = useState(getInitialLocal());
  localStorage.setItem("UserSelectedLanguage", currentLocale);
  const handleChange = (e: any) => {
    setCurrentLocale(e.target.value);
    // storing locale in the localstorage
    localStorage.setItem("UserSelectedLanguage", e.target.value);
  };

  //localstorage
  function getInitialLocal() {
    // getting stored items
    const savedLocale = localStorage.getItem("UserSelectedLanguage");
    return savedLocale || "en";
  }

  return (
    // <React.StrictMode>
    <IntlProvider locale={currentLocale} messages={flatten(messages[currentLocale])}>
      <MsalProvider instance={msalInstance}>
        <Provider store={store}>
          <Router history={history}>
            <AppProvider>
              <Layout currentLocale={currentLocale} handleChange={handleChange}>
                <Suspense fallback={<Loader />}>
                  <ToastContainer />
                  <Switch>{setRoutes(currentLocale, handleChange)}</Switch>
                </Suspense>
              </Layout>
            </AppProvider>
          </Router>
        </Provider>
      </MsalProvider>
    </IntlProvider>
    // </React.StrictMode>
  );
};
export default App;

ReactDOM.render(<App />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
