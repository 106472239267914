export const configApp = {
  //env: "https://np.api.us01e.bayer.com/int-bayerrewardsplus/api/"
  env: process.env.REACT_APP_BRP_API_HOST,
  envAuth: process.env.REACT_APP_OAUTH,
};
export const apiURL = {
  login: "user/login",
  scanLogs: "rsm/advisorsaleslog",
  productCategory: "user/getproductcategory",
  dashboardCount: "admin/dashboard",
  channelPartnersList: "user/userlist",
  retailerCreation: "user/v2.0/createexternaluserweb",
  deactivateChannelPartner: "user/deactivate",
  activateChannelPartner: "user/activate",
  updateUser: "user/update",
  downloadUserList: "user/download",
  changeLogs: "user/changelogs",
  downloadScanlogs: "order/advisororder/report",
  getTemplateData: "template/getTemplateByCountry",
  registerTemplateData: "template/templateForCountryRegister",
  rsmRetailerList: "order/users",
  getHierarchyLevels: "geographical/getRegLocHierLevelForWeb",
  getLevelFour: "geographical/getGeolevel4",
  getLevelFive: "geographical/getGeolevel5",
  getGeoLevelFive: "geographical/getgeolevel5new",
  downloadChanglogs: "user/changelogsdownload",
  adminOrderList: "admin/orderlist",
  downloadAdminOrderList: "admin/downloadorderlist",
  getScanLog: "admin/scanloglist",
  downloadAllScanLogs: "admin/downloadscanloglist/v2",
  getBatchList: "admin/batchlist",
  getPartnerList: "admin/partnerlist",
  featureToggle: "feature/getFeatures",
  updateFeatureToggle: "feature/EditFeatures",
  downloadTemplate: "inventory/report/template",
  downloadUserTemplate: "upload/usercreationtemplate",
  uploadTemplate: "inventory/v2/upload",
  uploadUsers: "upload/usercreation",
  checkphonenumber: "user/checkphonenumber",
  consolidatedScans: {
    getOverallScans: "scangoods/report/level1",
    getScannedBrands: "scangoods/report/level2",
    getScannedProducts: "scangoods/report/level3",
    downloadScans: "scangoods/report/download",
  },
  inventory: {
    //getOverallInventory: "inventory/report/level1",
    getBrandwiseInventory: "inventory/report/level2",
    getProductwiseInventory: "inventory/report/level3",
    getOverallInventory: "inventory/v2/report",
    downloadInventory: "inventory/v2/report/download",
    downloadTemplate: "inventory/report/template",
  },
  productInventory: {
    getProductInventoryDetails: "inventory/productreport",
    downloadProductInventory: "inventory/productreport/download",
  },
  getWarehouse: "scangoods/dispatch/history",
  getWarehouseDownload: "scangoods/dispatch/history/download",
  getWarehouseOptionsList: "scangoods/dispatch/history/warehouse",
  getMainBranchList: "user/mainbranchlist",
  // get API for roles,scantypes and package level
  getScanType: "lookup/getLookups",
  product: {
    getProductManage: "product/manage",
    getProductMaster: "product/getlist",
    downloadProductMaster: "product/download",
    editProduct: "product/edit",
    downloadTemplate: "upload/productedittemplate",
    uploadTemplate: "upload/producteditupload",
    changeLogs: "product/producteditlog",
    downloadChangeLogs: "product/downloadproducteditlog",
  },
  getLabelID: "labelId/getLogs",
  downloadLabelID: "labelId/downloadLogs",
  getproductsList: "returns/getproducts",
  getReturnedShopNamesList: "returns/partnerlist",
  fieldOfficers: {
    getSOHMappingList: "user/sohmappinglist",
    getFOUserList: "user/userlist",
    createFieldOfficer: "user/createthirdpartyuser",
    editFieldOfficer: "user/editthirdpartyuser",
    activateFOUser: "user/activate",
    deactivateFOUser: "user/deactivate",
    downloadFOUsers: "user/downloadthirdpartyuser",
  },
  getInvoice: "invoice",
  invoiceDownload: "invoice/download",
  createSOHProfile: "lookup/soh/setlastaccessprofile",
  soh: {
    getSohDetails: "lookup/soh/levels",
  },
  redemption: {
    list: "redemption/redeemproducthistory",
    download: "redemption/downloadredeemproducthistory",
  },
  anticounterfeit: {
    qrCodeDetails: "anticounterfeit/checkqr",
  },
};
