import { clearLocalStorageData, getLocalStorageData, setLocalStorageData } from "../utility/base/localStore";
import Cookies from "js-cookie";
import moment from "moment";
import { RSM_ROLE, ADMIN_ROLE, DEVADMIN_ROLE } from "../utility/constant";
import { getSohDetails } from "./helper";

class Authorization {
  authUser: any;
  sohUserList: any;
  authUserKey: string;
  sohListKey: string;

  constructor() {
    this.authUser = null;
    this.sohUserList = null;
    this.authUserKey = "userData";
    this.sohListKey = "loggedUserSOH";
  }

  //function
  logOut(isReload?: boolean): void {
    setLocalStorageData("isLoggedOut", true);
    clearLocalStorageData("userData");
    clearLocalStorageData("loggedUserSOH");
    clearLocalStorageData("sessionTime");
    clearLocalStorageData("ssoInfo");
    clearLocalStorageData("accessToken");
    clearLocalStorageData("currencycode");
    clearLocalStorageData("isOrderHistory");
    clearLocalStorageData("breadcrumData");
    clearLocalStorageData("accessProfile");
    clearLocalStorageData("isOneSOHAllLevel");
    clearLocalStorageData("isRemember");
    clearLocalStorageData("sohLookupDetails");
    clearLocalStorageData("countryInfo");
    clearLocalStorageData("nonsohlevel");
    sessionStorage.removeItem("userLoggedIn");
    Cookies.remove("userData");
    this.authUser = null;
    this.sohUserList = null;
    isReload && window.location.reload();
  }

  /**
   * check is active user is logged in
   */
  isLoggedIn(): boolean {
    //const data: any = getLocalStorageData(this.authUserKey);
    const data: any = getLocalStorageData(this.sohListKey);
    const ls = data;
    return ls && ls !== "" ? true : false;

    // return true;
  }
  /**
   * set auth user details to class property
   */
  setAuthUser() {
    const data: any = getLocalStorageData(this.authUserKey);
    this.authUser = data && JSON.parse(data);
  }
  /**
   * get logged in user details
   */
  getAuthUser() {
    if (this.isLoggedIn() && !this.authUser) {
      this.setAuthUser();
    }
    return this.authUser;
  }
  setSOHList() {
    const data: any = getLocalStorageData(this.sohListKey);
    this.sohUserList = data && JSON.parse(data);
  }
  /**
   * get logged in user details
   */
  getSOHList() {
    if (this.isLoggedIn() && !this.sohUserList) {
      this.setSOHList();
    }
    return this.sohUserList;
  }
  /**
   * login the user by setting it in local storage
   * @param {object} data
   */
  login(data: any) {
    if (typeof Storage !== "undefined") {
      clearLocalStorageData(this.authUserKey);
      //setLocalStorageData(this.authUserKey, JSON.stringify(data[0]));
      setLocalStorageData(this.sohListKey, JSON.stringify(data));
      setLocalStorageData("sessionTime", moment().unix());
      sessionStorage.userLoggedIn = true;
    } else {
      console.error("local storage is not supported");
    }
  }

  /**
   * check user is having the expected role
   *
   * @param role
   * @return boolean
   */
  isUserRole(role: string) {
    let user = this.getAuthUser();
    return user?.role && user.role === role;
  }
  /**
   * check logged user is admin
   *
   * @return boolean
   */
  isAdmin() {
    return this.isUserRole(ADMIN_ROLE);
  }
  /**
   * check logged user is RSM Admin
   *
   * @return boolean
   */
  isRSMAdmin() {
    return this.isSOHUser() ? false : this.isUserRole(RSM_ROLE);
  }
  /**
   * check logged user DEV Admin
   *
   * @return boolean
   */
  isDEVAdmin() {
    return this.isUserRole(DEVADMIN_ROLE);
  }
  /**
   * check logged user SOH User
   *
   * @return Boolean
   */
  isSOHUser() {
    let user = this.getAuthUser();
    const sohUser = user?.sohProfilesByCountry?.length > 0 ? true : false;
    return sohUser;
  }

  /**
   * Get authentication access token
   *
   * @return string
   */
  getAccessToken() {
    const accessToken: any = getLocalStorageData("accessToken");
    return accessToken ? JSON.parse(accessToken) : null;
  }
  getSOH(geoKey?: string) {
    let user = this.getAuthUser();
    const isRSM = this.isRSMAdmin();
    // SOH User
    if (this.isSOHUser()) {
      const result = getSohDetails(geoKey);
      return result;
    } else {
      // RSM or ADMIN
      let obj = {};
      //If getting soldby or soldto key geokey prefix value
      if (geoKey) {
        obj = { [`${geoKey + user?.sohgeolevel}code`]: isRSM ? user[`${user?.sohgeolevel}code`] : null };
      } else {
        obj = { [`${user?.sohgeolevel}code`]: isRSM ? user[`${user?.sohgeolevel}code`] : null };
      }
      return { ...obj };
    }
  }

  getLastAccessProfile() {
    const accessProfile: any = getLocalStorageData("accessProfile");
    return accessProfile ? JSON.parse(accessProfile) : null;
  }

  isRemember = () => {
    const isRemember: any = getLocalStorageData("isRemember");
    return isRemember === "true" ? true : false;
  };

  getTemplateCountry = () => {
    const countryInfo: any = getLocalStorageData("countryInfo");
    return countryInfo ? (typeof countryInfo === "string" ? JSON.parse(countryInfo) : countryInfo) : null;
  };
}

export default new Authorization();
