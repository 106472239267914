import { FIELDOFFICER_PERSONAL_DATA_ADD_INPUTLIST, SELECTED_LEVEL1_LIST } from "../actionTypes/userCreationTypes";

const INITIAL_STATE: any = {
  //   countryCode: null,
  //   countryName: null,
  //   currencyCode: null,
  //   currencyName: null,
  //   locale: null,
  foPersonalDatas: {
    active: true,
    firstname: "",
    lastname: "",
    email: "",
    mobilenumber: "",
  },
  mappingToOptions: [],
  editsaleshierlevel1code: [],
  editsaleshierlevel1description: [],
};

function userCreationReducer(state = INITIAL_STATE, action: any): any {
  switch (action.type) {
    case FIELDOFFICER_PERSONAL_DATA_ADD_INPUTLIST: {
      return {
        ...state,
        foPersonalDatas: action.payload,
      };
    }
    case SELECTED_LEVEL1_LIST: {
      let codes = action.payload.sohcodes;
      let mappedsohprofiles = action.payload.mappedsohprofiles;
      let editsaleshierlevel1description: any = [];
      mappedsohprofiles?.forEach((item: any) => {
        const dataFound = codes?.indexOf(item.saleshierlevel1code);
        if (dataFound !== -1) {
          editsaleshierlevel1description.push(item.saleshierlevel1description);
        }
      });
      return {
        ...state,
        editsaleshierlevel1code: codes,
        editsaleshierlevel1description: editsaleshierlevel1description,
        // editsaleshierlevel1code: ["RCCPS09"],
        // editsaleshierlevel1description: ["Kenya RCCP Territory 09"],
        // editsaleshierlevel1code: ["V03T08DGER1", "V03T07DGEC"],
        // editsaleshierlevel1description: ["DGE-Rice F&V - Territory CC1-BU1-R1-T1", "DGE-Corn - Territory CC1-BU1-R1-T2"],
      };
    }
    default:
      return state;
  }
}

export default userCreationReducer;
