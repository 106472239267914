import { FIELDOFFICER_PERSONAL_DATA_ADD_INPUTLIST, SELECTED_LEVEL1_LIST } from "../../actionTypes/userCreationTypes";

export const addFOPersonalInputList = (data: any) => ({
  payload: data,
  type: FIELDOFFICER_PERSONAL_DATA_ADD_INPUTLIST,
});

export const setSelectedLevel1List = (data: any) => ({
  payload: data,
  type: SELECTED_LEVEL1_LIST,
});
