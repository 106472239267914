import {
  DEV_CONFIG_LOCATION_ADD_DPLIST,
  DEV_CONFIG_LOCATION_ADD_INPUTLIST,
  DEV_CONFIG_ROLE_ADD_INPUTLIST,
  DEV_CONFIG_ROLE_ADD_DPLIST,
  DEV_CONFIG_TNTFLOW_ADD_INPUTLIST,
  DEV_CONFIG_PACKAGING_DEFINITION_ADD_INPUTLIST,
  DEV_CONFIG_SCANPOINTS_ALLOCATION_ADD_INPUTLIST,
  DEV_CONFIG_QRCODE_SETUP_ADD_INPUTLIST,
  DEV_CONFIG_RETURNS_ALLOCATION_ADD_INPUTLIST,
  DEV_CONFIG_TRANSACTION_BRANCH,
  DEV_CONFIG_ANTI_COUNTERFEIT_SET_SMS_AUTHENTICATION,
  DEV_CONFIG_ANTI_COUNTERFEIT_SET_DIGITAL_SCAN,
  DEV_CONFIG_ANTI_COUNTERFEIT_SET_SMART_LABEL,
  DEV_CONFIG_SET_COUNTRY_CODE,
  DEV_CONFIG_SET_COUNTRY_NAME,
  DEV_CONFIG_SET_CURRENCY_CODE,
  DEV_CONFIG_SET_CURRENCY_NAME,
  DEV_CONFIG_SET_LOCALE,
  DEV_CONFIG_ANONYMOUS,
  DEV_CONFIG_GPS_CAPTURE,
  DEV_CONFIG_LOYALITY,
  DEV_CONFIG_WINSAFE,
  DEV_CONFIG_NUMBER_OF_STAFF,
  DEV_CONFIG_SCAN_VALIDATION,
  DEV_CONFIG_ALLOW_QRCODE_SPACE, 
  DEV_CONFIG_QRCODE_CUSTOMIZED, 
  DEV_CONFIG_SET_MIN_VALUE,
  DEV_CONFIG_SET_MAX_VALUE,
  DEV_CONFIG_ACCEPTABLE_CHARS_VALUE,
  DEV_CONFIG_ADDITIONAL_CONFIG
} from "../actionTypes/devConfigTypes";

const INITIAL_STATE: any = {
  countryCode: null,
  countryName: null,
  currencyCode: null,
  currencyName: null,
  locale: null,
  location: {
    // dpList: [{ locationhierarchy: "", parentlocation: { id: 0, value: "NA" } }],
    // inputList: [{ locationhierarchy: "", parentlocation: { id: 0, value: "NA" } }]
    inputList: [{ level: 0, name: "", parentlevel: -1 }],
  },
  role: {
    // dpList: [{ rolecode: "", role: "", roletype: "", parentrole: { id: 0, value: "NA" } }],
    // inputList: [{ rolecode: "", role: "", roletype: "", parentrole: { id: 0, value: "NA" } }]
    inputList: [{ level: 0, code: "", name: "", type: "", parentrole: "NONE" }],
  },
  tntflow: {
    inputList: [{ level: 0, code: "", position: "" }],
  },
  packagingdefinition: {
    inputList: [{ productcategory: "", packaginghierarchylevel: 0, packaginghierarchyname: "", parentpackage: "" }],
  },
  scanpointsandallocation: {
    inputList: [{ position: 0, scannedby: "", scantype: "", packaginglevel: "", pointallocated: false }],
  },
  qrcodesetup: {
    inputList: [
      {
        orderno: 0,
        qr_generated_system: "",
        identifier: "",
        values_to_identify: [],
        prefixidentifiers: [],
        lengthidentifiers: "",
        noneidentifiers: "",
        lenght_of_qr_code: "",
        from_index: "",
        to_index: "",
        description: "",
      },
    ],
  },
  returnsallocation: {
    inputList: [{ position: 0, scannedby: "", returntype: "", packaginglevel: "", pointallocated: false }],
  },
  transactionscan: {
    inputList: [{}],
  },
  anticounterfeit: {
    sms_authentication: false,
    digital_scan: false,
    smart_label: false,
    isAnonymous: false,
    isGPSCapture: false,
    isloyaltyenabled: false,
    iswinsafe: false,
    totalStaffAllowed: 4,
  },
  maxqrcode: "",
  minqrcode:"",
  acceptableChars:"",
  isqrcodecustomized: false, 
  isallowqrcodespace: false
};

function devconfigReducer(state = INITIAL_STATE, action: any): any {
  const { payload, type } = action;
  switch (type) {
    case DEV_CONFIG_LOCATION_ADD_INPUTLIST: {
      const location: any = { ...state.location };
      location.inputList = payload;
      return {
        ...state,
        location: location,
      };
    }
    case DEV_CONFIG_LOCATION_ADD_DPLIST: {
      const location: any = { ...state.location };
      location.dpList = payload;
      return {
        ...state,
        location: location,
      };
    }
    case DEV_CONFIG_ROLE_ADD_INPUTLIST: {
      const role: any = { ...state.role };
      role.inputList = payload;
      return {
        ...state,
        role: role,
      };
    }
    case DEV_CONFIG_ROLE_ADD_DPLIST: {
      const role: any = { ...state.role };
      role.dpList = payload;
      return {
        ...state,
        role: role,
      };
    }
    case DEV_CONFIG_TNTFLOW_ADD_INPUTLIST: {
      const tntflow: any = { ...state.tntflow };
      tntflow.inputList = payload;
      return {
        ...state,
        tntflow: tntflow,
      };
    }
    case DEV_CONFIG_PACKAGING_DEFINITION_ADD_INPUTLIST: {
      const packagingdefinition: any = { ...state.packagingdefinition };
      packagingdefinition.inputList = payload;
      return {
        ...state,
        packagingdefinition: packagingdefinition,
      };
    }
    case DEV_CONFIG_SCANPOINTS_ALLOCATION_ADD_INPUTLIST: {
      const scanpointsandallocation: any = { ...state.scanpointsandallocation };
      scanpointsandallocation.inputList = payload;
      return {
        ...state,
        scanpointsandallocation: scanpointsandallocation,
      };
    }
    case DEV_CONFIG_QRCODE_SETUP_ADD_INPUTLIST: {
      const qrcodesetup: any = { ...state.qrcodesetup };
      qrcodesetup.inputList = payload;
      return {
        ...state,
        qrcodesetup: qrcodesetup,
      };
    }
    case DEV_CONFIG_RETURNS_ALLOCATION_ADD_INPUTLIST: {
      const returnsallocation: any = { ...state.returnsallocation };
      returnsallocation.inputList = payload;
      return {
        ...state,
        returnsallocation: returnsallocation,
      };
    }
    case DEV_CONFIG_TRANSACTION_BRANCH: {
      const transactionscan: any = { ...state.transactionscan };
      transactionscan.inputList = payload;
      return {
        ...state,
        transactionscan: transactionscan,
      };
    }
    case DEV_CONFIG_ANTI_COUNTERFEIT_SET_SMS_AUTHENTICATION: {
      let anticounterfeit = { ...state.anticounterfeit };
      anticounterfeit.sms_authentication = action.payload;
      return {
        ...state,
        anticounterfeit,
      };
    }
    case DEV_CONFIG_ANTI_COUNTERFEIT_SET_DIGITAL_SCAN: {
      let anticounterfeit = { ...state.anticounterfeit };
      anticounterfeit.digital_scan = action.payload;
      return {
        ...state,
        anticounterfeit,
      };
    }
    case DEV_CONFIG_ANTI_COUNTERFEIT_SET_SMART_LABEL: {
      let anticounterfeit = { ...state.anticounterfeit };
      anticounterfeit.smart_label = action.payload;
      return {
        ...state,
        anticounterfeit,
      };
    }
    case DEV_CONFIG_ANONYMOUS: {
      let anticounterfeit = { ...state.anticounterfeit };
      anticounterfeit.isAnonymous = action.payload;
      return {
        ...state,
        anticounterfeit,
      };
    }
    case DEV_CONFIG_GPS_CAPTURE: {
      let anticounterfeit = { ...state.anticounterfeit };
      anticounterfeit.isGPSCapture = action.payload;
      return {
        ...state,
        anticounterfeit,
      };
    }
    case DEV_CONFIG_NUMBER_OF_STAFF: {
      let anticounterfeit = { ...state.anticounterfeit };
      anticounterfeit.totalStaffAllowed = action.payload;
      return {
        ...state,
        anticounterfeit,
      };
    }
    case DEV_CONFIG_LOYALITY: {
      let anticounterfeit = { ...state.anticounterfeit };
      anticounterfeit.isloyaltyenabled = action.payload;
      return {
        ...state,
        anticounterfeit,
      };
    }

    case DEV_CONFIG_WINSAFE: {
      let anticounterfeit = { ...state.anticounterfeit };
      anticounterfeit.iswinsafe = action.payload;
      return {
        ...state,
        anticounterfeit,
      };
    }

    case DEV_CONFIG_SET_COUNTRY_CODE: {
      return {
        ...state,
        countryCode: action.payload,
      };
    }

    case DEV_CONFIG_SET_COUNTRY_NAME: {
      return {
        ...state,
        countryName: action.payload,
      };
    }

    case DEV_CONFIG_SET_CURRENCY_CODE: {
      return {
        ...state,
        currencyCode: action.payload,
      };
    }

    case DEV_CONFIG_SET_CURRENCY_NAME: {
      return {
        ...state,
        currencyName: action.payload,
      };
    }

    case DEV_CONFIG_SET_LOCALE: {
      return {
        ...state,
        locale: action.payload,
      };
    }
    case DEV_CONFIG_SCAN_VALIDATION: {
      return {
        ...state,
        scanValidation: action.payload,
      };
    }

    case DEV_CONFIG_ALLOW_QRCODE_SPACE: {
      return {
        ...state,
        isallowqrcodespace: action.payload,
      };
    }
    case DEV_CONFIG_QRCODE_CUSTOMIZED: {
      return {
        ...state,
        isqrcodecustomized: action.payload,
      };
    }
    case DEV_CONFIG_SET_MIN_VALUE: {
      return {
        ...state,
        minqrcode: action.payload,
      };
    }
    case DEV_CONFIG_SET_MAX_VALUE: {
      return {
        ...state,
        maxqrcode: action.payload,
      };
    }
    case DEV_CONFIG_ACCEPTABLE_CHARS_VALUE: {
      return {
        ...state,
        acceptableChars: action.payload,
      };
    }
    case DEV_CONFIG_ADDITIONAL_CONFIG: {
      return {
        ...state,
        addConfigData: action.payload,
      };
    }

    default:
      return state;
  }
}

export default devconfigReducer;
